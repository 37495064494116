define('fpl-fgdr-depositorweb-webapp/helpers/enum-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    onLanguageChange: Ember.observer('intl.locale', function () {
      this.recompute();
    }),

    compute: function compute(params, hash) {
      var value = params[0],
          type = hash.type;

      if (value !== undefined && value !== null) {
        if (type) {
          return this.get('intl').t('enums.' + type + '.' + value);
        } else {
          return value;
        }
      } else {
        return "";
      }
    }
  });
});