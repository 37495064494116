define('fpl-fgdr-depositorweb-webapp/components/rules-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        showRules1: false,

        init: function init() {
            this._super.apply(this, arguments);
        }
    });
});