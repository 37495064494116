define('fpl-fgdr-depositorweb-webapp/controllers/mycontactinformation', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _constants, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        labelClass: 'control-label col-xs-12 col-sm-3',
        fieldClass: 'col-xs-12 col-sm-6',
        showErrorMessage: false,
        showSuccessMessage: false,
        alertMessageNotHasRight: false,
        showSpinner: false,
        inputs: Ember.A(),
        postalCodes: Ember.inject.service(),

        initFormOptions: function initFormOptions() {
            var _this = this;

            var countries = new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/countries').then(function (response) {
                    _this.set('countries', response);
                    resolve();
                });
            });

            var languages = new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/communicationlanguages').then(function (response) {
                    _this.set('languages', response);
                    resolve();
                });
            });

            Promise.all([countries, languages]).then(function () {
                _this.set('didValidate', false);
            });
        },

        hasRightToUpdate: Ember.observer('model.recipientIdentity', function () {
            if (this.get('model.recipientIdentity') === this.get('model.ownerIdentity')) {
                this.set('hasRight', true);
            } else {
                this.set('alertMessageNotHasRight', true);
                this.set('hasRight', false);
            }
        }),
        shouldDisableContinue: Ember.computed('model.validations.isTruelyInvalid', 'model.mails.@each.{confirm,value}', 'model.phones.@each.{value}', function () {
            return this.get('model.validations.isTruelyInvalid') || this.hasMailsErrors() || this.hasPhonesErrors() || this.hasNoContactInformation();
        }),
        actions: {
            cancel: function cancel() {
                this.set('showErrorMessage', false);
                this.transitionToRoute('home');
            },
            validate: function validate() {
                var _this2 = this;

                if (this.get('model.validations.isTruelyValid')) {
                    this.set('showSpinner', true);
                    var deferred = Ember.RSVP.defer();
                    this.buildAjax.bind(this)(this.get('model'), this.get('inputs')).then(function () {
                        _this2.set('showErrorMessage', false);
                        // Step 1 : set showSuccessMessage to false to ensure it will not be displayed before transitionToRoute
                        _this2.set('showSuccessMessage', false);
                        _this2.get('currentUser').tunnelRedirectionContactInfo(deferred);
                    }).fail(function () {
                        _this2.set('showErrorMessage', true);
                        _this2.set('showSuccessMessage', false);
                        _this2.set('showSpinner', false);
                    });

                    deferred.promise.then(function (answer) {
                        _this2.send('displayAlertMessage', _this2.get('intl').t('my-contact-information.modify-success'), 'SUCCESS');
                        _this2.send('beforeTransitionTo');
                        _this2.set('showSpinner', false);
                        _this2.transitionToRoute(answer);
                    }, function () {
                        // Step 2 : tunnelRedirectionContactInfo failed so we stay on this page and we can show the success message
                        _this2.set('showSuccessMessage', true);
                        _this2.set('showSpinner', false);
                    });
                }
            },
            searchCity: function searchCity(term) {
                return this.get('postalCodes').find(term);
            },
            setCity: function setCity(codePostal) {
                this.set('model.address.postalCode', codePostal.codePostal);
                this.set('model.address.city', codePostal.nomCommune);
            }
        },

        hasMailsErrors: function hasMailsErrors() {
            var _this3 = this;

            var reducer = function reducer(accumulator, currentValue) {
                return accumulator && currentValue;
            };
            return !this.get('model.mails').map(function (mail) {
                return _this3.isMailValid(mail);
            }).reduce(reducer);
        },
        isMailValid: function isMailValid(mail) {
            var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (Ember.isEmpty(mail.value) || emailRegex.test(mail.value)) && mail.value === mail.confirm;
        },
        hasPhonesErrors: function hasPhonesErrors() {
            var phones = this.get('model.phones');
            for (var i = 0; i < phones.length; i++) {
                if (Ember.isEmpty(phones[i].value)) {
                    continue;
                }
                var selector = "#phone" + i + " input";
                var telInput = Ember.$(selector)[0];
                if (!(0, _commons.isTelValidFormat)(telInput)) {
                    return true;
                }
            }
            return false;
        },
        hasNoContactInformation: function hasNoContactInformation() {
            return this.get('model.mails').filter(function (mail) {
                return !Ember.isEmpty(mail.value);
            }).length === 0 && this.get('model.phones').filter(function (phone) {
                return !Ember.isEmpty(phone.value);
            }).length === 0;
        },
        init: function init() {
            this._super.apply(this, arguments);
            this.initFormOptions();
            this.set('phoneTypes', ['MOBILE', 'FIXE']);
        },
        formatMails: function formatMails(mails) {
            return mails.map(function (mail) {
                return mail.value;
            });
        },
        formatPhones: function formatPhones(phones, intl) {
            phones.forEach(function (phone, index) {
                return (
                    // eslint-disable-next-line no-undef
                    Ember.set(phone, 'value', intl[index].getNumber(intlTelInputUtils.numberFormat.E164))
                );
            });
        },
        buildAjax: function buildAjax(model, intl) {
            var dto = {};
            dto.ownerIdentity = model.ownerIdentity;
            dto.recipientIdentity = model.recipientIdentity;
            dto.address = model.address;
            this.formatPhones(model.phones, intl);
            dto.phones = model.phones;
            dto.validated = model.validated;
            dto.mails = this.formatMails(model.mails);
            dto.communicationLanguage = model.communicationLanguage;

            return Ember.$.ajax({
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                url: _constants.API_NAMESPACE + '/contacts/validate',
                data: JSON.stringify(dto),
                processData: false,
                contentType: false,
                type: 'POST'
            });
        }
    });
});