define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/confirmfinalization', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        init: function init(arg) {
            this._super(arg);
            var x = this;
            setTimeout(function () {
                x.transitionToRoute('login');
            }, 5000);
        }
    });
});