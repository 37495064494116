define('fpl-fgdr-depositorweb-webapp/models/authenticationchallenge-dto', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        identificationStrength: attr('string'),
        savingAccounts: attr(),
        checkingAccounts: attr(),
        creditCardDeclared: attr('boolean'),
        creditCardFirstNumbers: attr('string'),
        phones: attr(),
        emails: attr(),
        eligible: attr('boolean'),
        alreadyCreated: attr('boolean'),
        registrationNumber: attr('string')
    });
});