define('fpl-fgdr-depositorweb-webapp/models/mycontactinformation-dto', ['exports', 'ember-data', 'ember-cp-validations', 'ember-copy'], function (exports, _emberData, _emberCpValidations, _emberCopy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    var Validations = (0, _emberCpValidations.buildValidations)({
        ownerIdentity: (0, _emberCpValidations.validator)('presence', true),
        recipientIdentity: (0, _emberCpValidations.validator)('presence', true),
        'address.numAndWordingOfWay': (0, _emberCpValidations.validator)('presence', true),
        'address.postalCode': (0, _emberCpValidations.validator)('length', { is: 5 }),
        'address.city': (0, _emberCpValidations.validator)('presence', true),
        'address.country': (0, _emberCpValidations.validator)('presence', true),
        'communicationLanguage.code': (0, _emberCpValidations.validator)('presence', true),
        'communicationLanguage.wording': (0, _emberCpValidations.validator)('presence', true)
    });

    exports.default = _emberData.default.Model.extend(_emberCopy.Copyable, Validations, {
        validated: attr('string'),
        ownerIdentity: attr('string'),
        recipientIdentity: attr('string'),
        address: attr(),
        mails: attr(),
        phones: attr(),
        sendCommunicationsByLetter: attr(),
        communicationLanguage: attr()
    });
});