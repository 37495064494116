define('fpl-fgdr-depositorweb-webapp/routes/sendmessage', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.createRecord('communication-dto');
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('didValidate', false);
        },

        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});