define('fpl-fgdr-depositorweb-webapp/components/inputs/input-date', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field'], function (exports, _inputField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Date input
     *
     * ```hbs
     * ie : {{inputs/s-input inputId='identifier' labelId='identifier' value=model.attribute}}
     * ```
     */

    var input = _inputField.default.extend({
        intl: Ember.inject.service(),
        didInsertElement: function didInsertElement() {
            Ember.$('#datetimepicker').datetimepicker({
                // if you want to add more locales to date picker :
                // import the locale moment.js file in ember-cli-build.js
                locale: this.get('intl.locale').toLocaleString(),
                viewMode: 'years',
                format: 'DD/MM/YYYY'
            });
            Ember.$('#' + this.get('inputId')).mask('00/00/0000');
        },

        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],
        type: 'text',

        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid', 'shouldDisplayValidations').readOnly(),
        shouldDisplayValidations: Ember.computed('didValidate', 'hasContent', 'shouldValidate', function () {
            if (typeof this.get('shouldValidate') === 'boolean') {
                return (this.get('didValidate') || this.get('hasContent')) && this.get('shouldValidate');
            } else {
                return this.get('didValidate') || this.get('hasContent');
            }
        }).readOnly(),
        showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
            if (!this.get('inputId')) {
                this.set('inputId', 'input-' + this.get('inputSequence').next());
            }

            var name = this.get('name');
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + name));

            Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
        }
    });

    input.reopenClass({
        positionalParams: ['model']
    });

    exports.default = input;
});