define('fpl-fgdr-depositorweb-webapp/models/selectcampaign', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        campaignRef: attr('string'),
        memberRef: attr('string'),
        memberName: attr('string'),
        address: attr()
    });
});