define('fpl-fgdr-depositorweb-webapp/services/session-timeout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        currentUser: Ember.inject.service(),
        // Time in millisecond between the warning and the logout
        warningLogoutDelta: 30 * 1000,
        // Time in millisecond of a session
        sessionDuration: 600 * 1000,
        warningTimeout: Ember.computed('sessionDuration', 'warningLogoutDelta', function () {
            return this.get('sessionDuration') - this.get('warningLogoutDelta');
        }),

        timer: null,
        showWarning: false,

        initTimeout: function initTimeout() {
            var _this = this;

            this.set('showWarning', false);

            // set warning timeout
            this.setNextTimeout(this.get('warningTimeout'), function () {
                if (_this.get('currentUser').token() !== '') {
                    // show warning alert
                    _this.set('showWarning', true);
                    // set expired timeout
                    _this.setNextTimeout(_this.get('warningLogoutDelta'), function () {
                        // show expired alert
                        _this.set('showWarning', false);

                        // invalidate session
                        _this.get('currentUser').logoutWithoutLosingCampaign();

                        // clear timeout
                        _this.setNextTimeout();
                    });
                }
            });
        },
        setNextTimeout: function setNextTimeout() {
            var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            if (this.get('timer')) {
                window.clearTimeout(this.get('timer'));
            }

            // set new interval if needed
            if (timeout && callback) {
                this.set('timer', window.setTimeout(callback, timeout));
            }
        }
    });
});