define('fpl-fgdr-depositorweb-webapp/helpers/has-access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        currentUser: Ember.inject.service(),

        compute: function compute(params) {
            var role = params[0];
            return this.get('currentUser').hasAccess(role);
        }
    });
});