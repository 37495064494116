define('fpl-fgdr-depositorweb-webapp/serializer/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        serialize: function serialize(record, options) {
            options = options || { includeId: true };
            return this._super(record, options);
        },
        extractMeta: function extractMeta(store, type, payload) {
            var metadata = {};
            if (payload && type) {
                if (payload.total !== undefined) {
                    metadata.total = payload.total;
                    delete payload.total;
                }
                if (payload.pageable !== undefined) {
                    if (payload.pageable.page !== undefined) {
                        metadata.page = payload.pageable.page;
                    }
                    if (payload.pageable.size !== undefined) {
                        metadata.size = payload.pageable.size;
                    }
                    delete payload.pageable;
                }
            }
            return metadata;
        },


        normalizeResponse: function normalizeResponse(store, type, payload, id, requestType) {
            var content = Ember.isArray(payload) ? [] : {};
            if (payload) {
                if (payload.content) {
                    content = payload.content;
                    delete payload.content;
                } else if (Ember.isArray(payload)) {
                    content = content.concat(payload);
                    payload = {};
                } else {
                    for (var key in payload) {
                        if (payload.hasOwnProperty(key)) {
                            content[key] = payload[key];
                            delete payload[key];
                        }
                    }
                }
                payload[type.modelName] = content;
            }
            return this._super(store, type, payload, id, requestType);
        },

        serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
            Ember.merge(hash, this.serialize(record, options));
        }
    });
});