define('fpl-fgdr-depositorweb-webapp/routes/login', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        model: function model() {
            return this.store.createRecord('login-dto');
        },

        actions: {
            openModal: function openModal(modalName) {
                return this.render(modalName, {
                    into: 'application',
                    outlet: 'modal'
                });
            },
            closeModal: function closeModal() {
                return this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },

            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
                this.controller.set('showLogoutMessage', false);
                this.get('session').set('data.logout', false);
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            this.get('currentUser').logoutWithoutLosingCampaign();
            controller.set('didValidate', false);
            if (this.get('session.data.logout')) {
                controller.set('showLogoutMessage', true);
            }
        }
    });
});