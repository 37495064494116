define('fpl-fgdr-depositorweb-webapp/controllers/home', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),
        isAuthenticated: Ember.computed('currentUser.user.role', function () {
            return this.get('currentUser').hasAccess("CONNECTED_ROLE");
        }),
        shouldDisplayLastclLink: Ember.computed('model.lastLetter', function () {
            return this.get('model.lastLetter') !== '';
        }),
        isInitalSituationLetter: Ember.computed('model.lastLetter', function () {
            return this.get('model.lastLetter.communicationType') === 'INITIAL_SITUATION_LETTER';
        })
    });
});