define('fpl-fgdr-depositorweb-webapp/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _applicationRouteMixin, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
        intl: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        canSuppressMessage: false,
        actions: {
            displayAlertMessage: function displayAlertMessage(message, type) {
                switch (type) {
                    case 'SUCCESS':
                        this.set('controller.showAlertMessage', true);
                        this.set('controller.typeAlert', type);
                        this.set('controller.alertMessage', message);
                        break;
                    case 'ERROR':
                        this.set('controller.showErrorMessage', true);
                        this.set('controller.typeError', type);
                        this.set('controller.errorMessage', message);
                        break;
                    case 'INFO':
                        this.set('controller.showInfoMessage', true);
                        this.set('controller.typeInfo', type);
                        this.set('controller.infoMessage', message);
                        break;
                    case 'WARNING':
                        this.set('controller.showWarningMessage', true);
                        this.set('controller.typeWarning', type);
                        this.set('controller.warningMessage', message);
                        break;
                }
            },


            /**
             * Call only when page send and receive a displayAlertMessage
             * Used to not suppress directly the display of message when using transitionTo (use with resetMessage in willTransition)
             */
            beforeTransitionTo: function beforeTransitionTo() {
                this.set('canSuppressMessage', true);
            },
            resetMessage: function resetMessage() {
                if (this.get('canSuppressMessage')) {
                    this.set('canSuppressMessage', false);
                } else {
                    this.set('controller.showAlertMessage', false);
                    this.set('controller.showErrorMessage', false);
                    this.set('controller.showInfoMessage', false);
                    this.set('controller.showWarningMessage', false);
                }
            },
            resetErrorMessage: function resetErrorMessage() {
                if (this.get('canSuppressMessage')) {
                    this.set('canSuppressMessage', false);
                } else {
                    this.set('controller.showErrorMessage', false);
                }
            },
            getNewCommunicationsCount: function getNewCommunicationsCount() {
                var _this = this;

                if (this.get('session.isAuthenticated') && this.get('currentUser').hasAccess('CONNECTED_ROLE')) {
                    Ember.$.get("/api/communications/newCommunicationsCount/").then(function (response) {
                        _this.get('session').set('data.newCommunicationCount', response);
                    });
                }
            },
            decreaseNewCommunicationsCount: function decreaseNewCommunicationsCount() {
                this.get('session').set('data.newCommunicationCount', this.get('session').get('data.newCommunicationCount') - 1);
            }
        },
        sessionAuthenticated: function sessionAuthenticated() {
            this._loadSession();
            this.transitionTo('home');
        },
        _loadSession: function _loadSession() {
            return this.get('currentUser').load();
        },
        beforeModel: function beforeModel() {
            var _this2 = this;

            if (Ember.isEmpty(this.get('session').get('data.selectedLanguage'))) {
                this.get('session').set('data.selectedLanguage', _constants.DEFAULT_LANGUAGE);
            }

            this.get('intl').setLocale(this.get('session').get('data.selectedLanguage'));

            if (this.get('session.isAuthenticated') && this.get('currentUser').hasAccess('CONNECTED_ROLE')) {
                Ember.$.get(_constants.API_NAMESPACE + "/communications/newCommunicationsCount/").then(function (response) {
                    _this2.get('session').set('data.newCommunicationCount', response);
                });
            }

            return this._loadSession();
        }
    });


    Ember.Route.reopen({
        currentUser: Ember.inject.service(),
        intl: Ember.inject.service()
    });
});