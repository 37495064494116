define('fpl-fgdr-depositorweb-webapp/routes/loginotp', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('PROVIDE_LOGIN_OTP_ROLE')) {
                this.transitionTo('home');
            }
            var controller = this.controllerFor('loginotp');
            return new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/contacts/loginotp').then(function (response) {
                    controller.set('phoneOptions', response.phones);
                    controller.set('emailOptions', response.emails);
                    resolve(response);
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('nbAttempt', 0);
            controller.set('formValid', false);
            controller.set('otpSent', false);
        },

        actions: {
            // When you leave the page, you are disconnected, except if otp is valid or account is blocked
            willTransition: function willTransition() {
                if (!(this.controllerFor('loginotp').get('formValid') || this.controllerFor('loginotp').get('isBlocked'))) {
                    this.get('session').invalidate();
                }
            }
        }
    });
});