define('fpl-fgdr-depositorweb-webapp/models/creditcard-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        lastFourNumber: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.creditcard.not-empty-field'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^[0-9]+$/,
            messageKey: 'errors.creditcard.number.bad-format'
        }), (0, _emberCpValidations.validator)('length', {
            is: 4,
            messageKey: 'errors.creditcard.lastFourNumber-size-error'
        })],
        expirationDate: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.creditcard.not-empty-field'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(0[1-9]|1[0-2])\/\d{2}$/,
            messageKey: 'errors.creditcard.expiration-date.bad-format'
        })]
    });

    /**
     * Credit card data model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        lastFourNumber: attr('string'),
        expirationDate: attr('string')
    });
});