define('fpl-fgdr-depositorweb-webapp/services/postal-codes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var index = {};

    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
            Ember.$.getJSON("/codes-postaux.json", function (result) {
                Ember.$.each(result, function (i, entry) {
                    if (!(entry.codePostal in index)) {
                        index[entry.codePostal] = [];
                    }
                    index[entry.codePostal].push(entry);
                });
            });
        },
        find: function find(postalCode) {
            return index[postalCode] || [];
        }
    });
});