define('fpl-fgdr-depositorweb-webapp/validators/iban-validator', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var IbanValidator = _base.default.extend({
        intl: Ember.inject.service(),

        validate: function validate(value, options, model) {
            if (Ember.isEmpty(value) && (!model.get('bicIbanZoneMandatory') || model.get('paymentMode') === "CHEQUE")) {
                return true;
            }
            var rgx = RegExp('^FR\\p{Alnum}{2}' + this.get('model.memberRef') + '\\p{Alnum}*');
            // IBAN is import in cli-build (Unable to import lib otherwise)
            /*eslint-disable */
            var ibanValid = IBAN.isValid(value);
            /*eslint-enable */
            if (ibanValid && !rgx.test(this.get('model.iban'))) {
                return true;
            }
            return this.get('intl').t('errors.paymentDetails.notValidIBAN');
        }
    });

    exports.default = IbanValidator;
});