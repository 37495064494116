define('fpl-fgdr-depositorweb-webapp/components/inputs/input-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        fieldClass: 'col-xs-12 col-sm-8',
        labelClass: 'control-label col-xs-12 col-sm-4',
        requiredField: true,
        didValidate: false,
        disableValidations: false,
        showErrorMessage: Ember.computed('didValidate', 'errorMessage', 'errorMessage2', 'inputValue', 'disableValidations', function () {
            return (this.get('didValidate') || !Ember.isEmpty(this.get('inputValue'))) && (!Ember.isEmpty(this.get('errorMessage')) || !Ember.isEmpty(this.get('errorMessage2'))) && !this.get('disableValidations');
        }),
        customErrorMessage: Ember.computed('errorMessage', 'errorMessage2', function () {
            if (this.get('errorMessage')) {
                return this.get('errorMessage');
            } else {
                return this.get('errorMessage2');
            }
        })
    });
});