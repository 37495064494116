define('fpl-fgdr-depositorweb-webapp/services/commons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.separateBySpaceEveryFourCharacter = separateBySpaceEveryFourCharacter;
    exports.getErrorKey = getErrorKey;
    exports.substringBefore = substringBefore;
    exports.substringAfterLast = substringAfterLast;
    exports.getMissingDigitsCount = getMissingDigitsCount;
    exports.getMissingDigitsInputWidth = getMissingDigitsInputWidth;
    exports.initAuthenticationanswersModel = initAuthenticationanswersModel;
    exports.isTelValidFormat = isTelValidFormat;


    /**
     * Format contract number putting space every 4 character.
     *
     * @param string account reference
     * @return formated reference
     */
    function separateBySpaceEveryFourCharacter(string) {
        if (string === null) {
            return "";
        }
        var formatted = string.replace(/ /g, "");
        var i = 4;
        while (i < formatted.length) {
            formatted = formatted.slice(0, i) + " " + formatted.slice(i);
            i = i + 5;
        }
        return formatted;
    }

    /**
     * Function to retrieve the message key from a given base.
     *
     * @param {String}
     *          base the base
     * @param {Function}
     *          exists the function to check if the resulting key exists in a catalog
     * @param {String}
     *          defaultValue to return if the resulting key does not exist or if the base does not contain a key.
     */
    function getErrorKey(base, exists) {
        var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'error.back.generic';

        var key = void 0;
        if (base.indexOf('key:') === 0) {
            key = base.replace('key:', '');
            if (!exists(key)) {
                key = defaultValue;
            }
        } else {
            key = defaultValue;
        }
        return key;
    }

    /**
     * Return the first part in string before a regex
     *
     * @param string is account reference : ie -> FR76300040301400000******33
     * @param regex
     * @return {*}
     */
    function substringBefore(string, regex) {
        if (Ember.isEmpty(string) || Ember.isEmpty(regex)) {
            return "";
        }
        return string.substr(0, string.indexOf('*'));
    }

    /**
     * Return string a item
     *
     * @param string
     * @param regex will be {',', '*', '-'...}
     * @return formatted string
     */
    function substringAfterLast(string, regex) {
        if (Ember.isEmpty(string) || Ember.isEmpty(regex)) {
            return "";
        }
        return string.substring(string.lastIndexOf(regex) + 1);
    }

    /**
     * Return number of * in accountRef
     *
     * @param accountRef account's reference : ie FR76300040301400000******33
     * @return {number}
     */
    function getMissingDigitsCount(accountRef) {
        return (accountRef.match(/\*/g) || []).length;
    }

    /**
     * Give the width of missing digits
     *
     * @param contractRef -> ie FR76300040301400000******33
     * @param firstDigits -> ie FR76300040301400000
     * @param lastDigits -> ie 33
     */
    function getMissingDigitsInputWidth(contractRef, firstDigits, lastDigits) {
        var totalPx = 0;
        var paddingSize = 14;
        var charSize = 8.4;
        var nbOfPaddings = 2 + (Ember.isEmpty(firstDigits) ? 0 : 2) + (Ember.isEmpty(lastDigits) ? 0 : 2);
        totalPx += nbOfPaddings * paddingSize;
        totalPx += contractRef.length * charSize;
        return Math.ceil(totalPx);
    }

    function initAuthenticationanswersModel() {
        return {
            dfTelematicRef: '',
            identificationStrength: '',
            creditCardAnswer: {},
            savingAccountNumber: '',
            checkingAccountNumber: '',
            confirmedEmail: '',
            confirmedPhone: '',
            phoneValueSecured: ''
        };
    }

    function isTelValidFormat(input) {
        // eslint-disable-next-line no-undef
        var iti = intlTelInputGlobals.getInstance(input);
        if (!iti) {
            // Tel input not fully initialized : the intl part has not been added to the dom yet
            // We consider that the phone number is valid for now
            return true;
        }
        return iti.isValidNumber();
    }

    /**
     * Application constants functions.
     */
    exports.default = Ember.Service.extend({});
});