define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/identification/identity', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        model: function model() {
            return this.store.createRecord('weakidentificationrequest-dto', {
                naturalPerson: true
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('showErrorMessage', false);
            controller.set('didValidate', false);
        },

        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});