define('fpl-fgdr-depositorweb-webapp/controllers/payment', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons', 'ember-copy'], function (exports, _constants, _commons, _emberCopy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        selection: null,
        onlyTransferAvailable: false,
        onlyChequeAvailable: false,
        chequeAndTransferAvailable: false,
        onlyTransferAvailableOrNotFGDR: false,
        showSpinner: false,
        hasNotChosenPaymentMode: Ember.computed('model.paymentMode', function () {
            return this.get('model.paymentMode') === 'NONE';
        }),
        shouldDisableContinue: Ember.computed('onlyChequeAvailable', 'model.validations.isTruelyInvalid', function () {
            return !this.get('onlyChequeAvailable') && this.get('model.validations.isTruelyInvalid');
        }),
        hasChosenTransfer: Ember.computed('model.paymentMode', function () {
            return this.get('model.paymentMode') === 'TRANSFER';
        }),
        currentIsTransfer: Ember.computed('hasChosenTransfer', 'onlyTransferAvailableOrNotFGDR', function () {
            return this.get('hasChosenTransfer') || this.get('onlyTransferAvailableOrNotFGDR');
        }),
        disableTransferFields: Ember.computed('hasChosenTransfer', function () {
            return !this.get('hasChosenTransfer');
        }),
        actions: {
            cancel: function cancel() {
                this.set('showErrorMessage', false);
            },
            validate: function validate() {
                var _this = this;

                if (this.get('onlyChequeAvailable') || this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var deferred = Ember.RSVP.defer();
                    if (this.get('currentIsTransfer') && !Ember.isEmpty(this.get('model.bic'))) {
                        Ember.$.get(_constants.API_NAMESPACE + '/payment/validate/' + this.get('model.bic')).then(function (response) {
                            if (response) {
                                _this.set('showErrorMessageBic', false);
                                buildAjax.bind(_this)(_this.get('model'), deferred);
                            } else {
                                _this.set('showErrorMessageBic', true);
                            }
                            _this.set('showSpinner', false);
                        }, function (error) {
                            _this.send('displayAlertMessage', _this.get('intl').t((0, _commons.getErrorKey)(error.responseJSON.errors.message, function (value) {
                                return _this.get('intl').exists(value);
                            }, 'errors.generic')), 'ERROR');
                            _this.set('showSpinner', false);
                        });
                    } else {
                        // reset transfer data if needed
                        if (!this.get('currentIsTransfer')) {
                            this.set('model.bic', (0, _emberCopy.copy)(this.get('paymentCopyBic')));
                            this.set('model.iban', (0, _emberCopy.copy)(this.get('paymentCopyIban')));
                            this.set('model.accountOwner', (0, _emberCopy.copy)(this.get('paymentCopyAccountOwner')));
                            this.set('model.cni', (0, _emberCopy.copy)(this.get('paymentCopyCni')));
                        }
                        this.set('showErrorMessageBic', false);
                        buildAjax.bind(this)(this.get('model'), deferred);
                    }
                    deferred.promise.then(function (value) {
                        _this.send('beforeTransitionTo');
                        _this.set('showSpinner', false);
                        _this.transitionToRoute(value);
                    }).catch(function () {
                        _this.set('showSpinner', false);
                    });
                }
            }
        }
    });


    function buildAjax(model, d) {
        var _this2 = this;

        Ember.$.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            url: _constants.API_NAMESPACE + '/df/paymentMode',
            data: JSON.stringify(model),
            processData: false,
            contentType: false,
            type: 'POST'
        }).then(function () {
            _this2.set('payment-dto.paymentMode', model.paymentMode);
            _this2.set('showErrorMessage', false);
            _this2.send('resetMessage');
            _this2.send('displayAlertMessage', _this2.get('intl').t('payment.successfullyUpdated'), 'SUCCESS');
            _this2.get('currentUser').tunnelRedirectionPaymentInfo(d);
        }).fail(function (reason) {
            if (reason.responseJSON.errors.message === 'key:errors.exception.PaymentModeException') {
                _this2.set('showErrorMessage', false);
                _this2.set('showErrorPaymentModeMessage', true);
            } else {
                _this2.set('showErrorPaymentModeMessage', false);
                _this2.set('showErrorMessage', true);
            }
        });
    }
});