define('fpl-fgdr-depositorweb-webapp/components/session-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentUser: Ember.inject.service(),
        sessionTimeout: Ember.inject.service(),
        showWarning: Ember.computed.alias('sessionTimeout.showWarning'),

        actions: {
            keepAlive: function keepAlive() {
                // refresh current user
                if (this.get('currentUser').token() !== '') {
                    this.get('currentUser').refresh();
                    this.get('sessionTimeout').initTimeout();
                }
            },
            logout: function logout() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            connect: function connect() {
                if (this.transitionTo) {
                    this.transitionTo('home');
                }
            }
        }
    });
});