define('fpl-fgdr-depositorweb-webapp/components/error-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        title: null,
        message: null,
        messages: null,
        showErrorMessage: false,

        init: function init() {
            this._super.apply(this, arguments);
            if (!Ember.isEmpty(this.get('message'))) {
                this.set('messages', [this.get('message')]);
            }
        },

        actions: {
            close: function close() {
                this.set('showErrorMessage', false);
            }
        }
    });
});