define('fpl-fgdr-depositorweb-webapp/routes/updatepassword', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        beforeModel: function beforeModel(controller, model) {
            if (!this.get('currentUser').hasAccess('CONNECTED_ROLE')) {
                this.transitionTo('home');
            }
            this._super(controller, model);
        },
        model: function model() {
            return this.store.createRecord('updatepassword');
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('didValidate', false);
        }
    });
});