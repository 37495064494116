define('fpl-fgdr-depositorweb-webapp/routes/accountlocked', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        actions: {
            willTransition: function willTransition() {
                this.get('session').invalidate();
            }
        }
    });
});