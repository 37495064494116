define('fpl-fgdr-depositorweb-webapp/components/side-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            invalidateSession: function invalidateSession() {
                this.get('invalidateSession')();
            },
            goToSelectCampaign: function goToSelectCampaign() {
                this.get('session').set('data.campaign-dto', {});
                this.get('invalidateSession')();
            }
        }
    });
});