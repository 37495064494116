define('fpl-fgdr-depositorweb-webapp/components/message-panel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        title: null,
        message: null,
        showMessage: false,
        messagesToDisplay: Ember.computed('messages', function () {
            if (typeof this.get('messages') === 'string') {
                var messagesTab = [];
                messagesTab.push(this.get('messages'));
                return messagesTab;
            } else {
                return this.get('messages');
            }
        }),
        actions: {
            close: function close() {
                this.set('showMessage', false);
            }
        }
    });
});