define('fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            if (!this.get('currentUser').hasAccess('CONNECTED_ROLE')) {
                transition.abort();
                this.transitionTo('login');
            } else {
                return this._super.apply(this, arguments);
            }
        }
    });
});