define('fpl-fgdr-depositorweb-webapp/models/otpcode', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Data validation
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        value: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.accountcreation.authentication.otp.confirmation-code-validation'
        })]
    });

    /**
     * Represents phone object
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        value: attr('string')
    });
});