define('fpl-fgdr-depositorweb-webapp/models/resetpassword', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        newPassword: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.accountcreation.authentication.finalization.password.not-empty-field'
        })],
        passwordConfirmation: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.accountcreation.authentication.finalization.confirmation.not-empty-field'
        }), (0, _emberCpValidations.validator)('confirmation', {
            on: 'newPassword',
            messageKey: 'errors.accountcreation.authentication.finalization.confirmation.notIdentical'
        })]
    });

    /**
     * reset password request data model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        newPassword: attr('string'),
        passwordConfirmation: attr('string')
    });
});