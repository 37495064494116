define('fpl-fgdr-depositorweb-webapp/controllers/forgotpasswordotp', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        subOtpToken: _constants.OTP_STUBMODE,
        showSpinner: false,
        actions: {
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            validate: function validate() {
                var _this = this;

                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var otp = this.get('model.value');
                    this.get('session').authenticate('authenticator:jwt-custom', otp, '/otp/validateloginforgotpassword').then(function () {
                        _this.set('showSpinner', false);
                        _this.send('resetErrorMessage');
                        _this.transitionToRoute('resetpassword');
                    }).catch(function (reason) {
                        if (reason.responseJSON.errors.message === "key:errors.exception.AccountLockedException") {
                            _this.set('showSpinner', false);
                            _this.set('isBlocked', true);
                            _this.transitionToRoute('accountlocked');
                        } else {
                            _this.send('displayAlertMessage', _this.get('intl').t('errors.forgotpassword.otp.invalid'), 'ERROR');
                            _this.set('showSpinner', false);
                        }
                    });
                }
            }
        }
    });
});