define('fpl-fgdr-depositorweb-webapp/controllers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        moreThanOneCampaignAvailable: false,
        checkIfCampaignExist: true,
        testMode: true
    });
});