define('fpl-fgdr-depositorweb-webapp/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            this.replaceWith('selectcampaign');
        },

        actions: {
            openModal: function openModal(modalName) {
                return this.render(modalName, {
                    into: 'application',
                    outlet: 'modal'
                });
            },
            closeModal: function closeModal() {
                return this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            }
        }
    });
});