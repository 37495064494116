define('fpl-fgdr-depositorweb-webapp/routes/testcampaignaccess', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _constants, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            this._super(transition);
            this.get('session').set('data.campaign-dto', {});
            if (transition.queryParams.campaignRef !== undefined) {
                // get all campaigns
                Ember.$.get(_constants.API_NAMESPACE + "/campaigns/visibleCampaigns?testMode=true").then(function (response) {
                    var campaignDefault = response.find(function (element) {
                        return element.campaignRef === transition.queryParams.campaignRef;
                    });
                    // set value of campaignDefault, either campaignSelected in queryParams if campaign exist, or undefined otherwise
                    _this.get('session').set('data.campaign-dto', campaignDefault);
                    _this.transitionTo('login');
                }).fail(function () {
                    _this.transitionTo('selectcampaign');
                });
            } else {
                this.transitionTo('selectcampaign');
            }
            this.get('session').set('data.campaignTest', true);
        }
    });
});