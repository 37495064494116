define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/identification/reference', ['exports', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        showSpinner: false,
        shouldDisableContinue: Ember.computed('model.{validations.isTruelyInvalid,hasReference}', function () {
            return this.get('model.validations.isTruelyInvalid') && this.get('model.hasReference');
        }),
        actions: {
            validate: function validate() {
                var _this = this;

                this.set('alertType', 'alert-info');
                if (this.get('model.validations.isTruelyInvalid')) {
                    this.set('showErrorMessage', true);
                } else {
                    if (this.get('model.hasReference')) {
                        var payload = {
                            campaignRef: this.get('session.data.campaign-dto').campaignRef,
                            telematicRef: this.get('model.telematicRef'),
                            nameOrDenominationOrSiren: this.get('model.nameOrDenominationOrSiren')
                        };
                        this.set('showSpinner', true);
                        this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(payload), '/identification/strong').then(function () {
                            _this.get('session').set('data.authenticationchallenge-dto', _this.get('session.data.authenticated.data'));
                            var authenticationanswers = (0, _commons.initAuthenticationanswersModel)();
                            authenticationanswers.dfTelematicRef = _this.get('session.data.authenticated.data.dfTelematicRef');
                            _this.get('session').set('data.authenticationanswers-dto', authenticationanswers);
                            _this.set('showSpinner', false);
                            _this.transitionToRoute('accountcreation.authentication.bankinfo');
                        }).catch(function (reason) {
                            _this.set('showSpinner', false);
                            if (reason.responseJSON.errors.message === "key:errors.exception.IdentificationAccountNotEligibleException") {
                                _this.transitionToRoute('accountcreation.noteligible');
                            } else if (reason.responseJSON.errors.message === "key:errors.exception.EnrolmentAccountLockedException") {
                                _this.transitionToRoute('accountlocked');
                            } else {
                                _this.send('displayAlertMessage', _this.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                                    return _this.get('intl').exists(value);
                                }, 'errors.exception.AuthenticationException')), 'ERROR');
                            }
                        });
                    } else {
                        this.transitionToRoute('accountcreation.identification.identity');
                    }
                }
            },
            cancel: function cancel() {
                this.transitionToRoute('login');
            }
        }
    });
});