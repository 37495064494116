define('fpl-fgdr-depositorweb-webapp/routes/selectcampaign', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _constants, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),
        beforeModel: function beforeModel() {
            if (this.get('session.data.campaign-dto.campaignRef')) {
                this.transitionTo('login');
            }
        },
        model: function model() {
            var _this = this;

            if (this.get('session.data.campaignTest') === undefined) {
                this.get('session').set('data.campaignTest', false);
            }
            return new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/campaigns/visibleCampaigns?testMode=' + _this.get('session.data.campaignTest')).then(function (response) {
                    resolve(response);
                });
            });
        },
        afterModel: function afterModel(campaigns) {
            if (campaigns !== null && campaigns.get('length') === 1) {
                this.get('session').set('data.campaign-dto', campaigns.get(0));
                this.get('session').set('multipleCampaigns', false);
                this.transitionTo('login');
            } else if (campaigns !== null && campaigns.get('length') > 1) {
                this.get('session').set('multipleCampaigns', true);
            }
        }
    });
});