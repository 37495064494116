define('fpl-fgdr-depositorweb-webapp/controllers/forgotpassword', ['exports', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showSpinner: false,
        actions: {
            cancel: function cancel() {
                this.transitionToRoute('login');
            },
            validate: function validate() {
                var _this = this;

                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var payload = {
                        campaignRef: this.get('campaignRef'),
                        telematicRef: this.get('model.telematicRef'),
                        nameOrDenominationOrSiren: this.get('model.nameOrDenominationOrSiren')
                    };
                    this.set('showSpinner', true);
                    this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(payload), '/auth/authforgotpassword').then(function () {
                        _this.set('didValidate', false);
                        _this.transitionToRoute('forgotpasswordmails');
                    }, function (error) {
                        _this.set('showSpinner', false);
                        _this.send('displayAlertMessage', _this.get('intl').t((0, _commons.getErrorKey)(error.responseJSON.errors.message, function (value) {
                            return _this.get('intl').exists(value);
                        }, 'errors.exception.AuthenticationException')), 'ERROR');
                    });
                }
            }
        }
    });
});