define('fpl-fgdr-depositorweb-webapp/components/inputs/textarea-input', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field'], function (exports, _inputField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Textarea Input.
     *
     * ```hbs
     * ie : {{inputs/textarea-input inputId='identifier' value=model.attribute}}
     * ```
     */
    var InputComponent = _inputField.default.extend({
        classNameBindings: ['showErrorMessage:has-error', 'isValid:has-success'],
        type: 'text',
        class: 'form-control',
        hasContent: Ember.computed.notEmpty('value').readOnly(),
        isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid', 'shouldDisplayValidations').readOnly(),
        shouldDisplayValidations: Ember.computed.or('didValidate', 'hasContent').readOnly(),
        showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
            var name = this.get('name');
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + name));
            Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
        }
    });

    InputComponent.reopenClass({
        positionalParams: ['model']
    });

    exports.default = InputComponent;
});