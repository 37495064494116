define('fpl-fgdr-depositorweb-webapp/models/contactrequest-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        naturalPerson: (0, _emberCpValidations.validator)('presence', true),
        lastName: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.contact.lastName.not-empty-field',
            disabled: Ember.computed('model.{naturalPerson,model.isAuthenticated}', function () {
                return !this.get('model.naturalPerson') || this.get('model.isAuthenticated');
            }).volatile()
        })],
        firstName: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,model.isAuthenticated}', function () {
                return !this.get('model.naturalPerson') || this.get('model.isAuthenticated');
            }).volatile(),
            messageKey: 'errors.contact.firstName.not-empty-field'
        })],
        registrationNumber: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,model.isAuthenticated}', function () {
                return this.get('model.naturalPerson') || this.get('model.isAuthenticated');
            }).volatile(),
            messageKey: 'errors.contact.registrationNumber.not-empty-field'
        })],
        denomination: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,model.isAuthenticated}', function () {
                return this.get('model.naturalPerson') || this.get('model.isAuthenticated');
            }).volatile(),
            messageKey: 'errors.contact.denomination.not-empty-field'
        })]
    });
    /**
     * Represents data model for Weak Authentification
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        naturalPerson: attr('boolean'),
        lastName: attr('string'),
        firstName: attr('string'),
        registrationNumber: attr('string'),
        denomination: attr('string'),
        selectedForm: attr('string')
    });
});