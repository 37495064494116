define('fpl-fgdr-depositorweb-webapp/routes/forgotpasswordmails', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _constants, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('CONSULT_LIST_COMMUNICATION_AND_SEND_OTP_ROLE')) {
                this.transitionTo('home');
            }
        },
        model: function model() {
            var context = this;
            return new Promise(function (resolve, reject) {
                Ember.$.get(_constants.API_NAMESPACE + '/contacts/loginotpforgotpassword').then(function (response) {
                    resolve(response);
                }, function (error) {
                    context.send('displayAlertMessage', context.get('intl').t((0, _commons.getErrorKey)(error.responseJSON.errors.message, function (value) {
                        return context.get('intl').exists(value);
                    }, 'errors.exception.NotEnoughDataException')), 'ERROR');
                    context.transitionTo('login');
                    reject(error);
                });
            });
        }
    });
});