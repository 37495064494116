define('fpl-fgdr-depositorweb-webapp/components/breadcrumb-trail', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            // force the depositor to set his payment mode
            if (!this.get('statuses.paymentInformationValidated')) {
                this.set('statuses.paymentMode', 'NONE');
            }

            if (this.get('statuses.contactInformationValidated') && this.get('statuses.paymentInformationValidated')) {
                this.set('dfInfoStatusClassName', 'is-finished');
            } else if (this.get('statuses.contactInformationValidated') || this.get('statuses.paymentInformationValidated')) {
                this.set('dfInfoStatusClassName', 'is-active');
            } else {
                this.set('dfInfoStatusClassName', 'is-pending');
            }

            var dfStatusClassName = void 0;
            if (this.get('statuses.depositorFileStatus') === 'IN_PROGRESS' || this.get('statuses.depositorFileStatus') === 'AWAITING') {
                dfStatusClassName = 'is-active';
            } else {
                dfStatusClassName = 'is-finished';
            }

            var iaStatusClassName = void 0;
            if (this.get('statuses.initialAmountStatus') === 'NONE') {
                if (dfStatusClassName === 'is-active') {
                    dfStatusClassName = dfStatusClassName + ' last';
                }
                iaStatusClassName = 'is-pending';
            } else if (this.get('statuses.initialAmountStatus') === 'PARTIALLY') {
                iaStatusClassName = 'is-active';
            } else {
                iaStatusClassName = 'is-finished';
            }

            var paStatusClassName = void 0;
            if (this.get('statuses.paidAmountStatus') === 'NONE') {
                if (iaStatusClassName === 'is-active') {
                    iaStatusClassName = iaStatusClassName + ' last';
                }
                paStatusClassName = 'is-pending';
            } else if (this.get('statuses.paidAmountStatus') === 'PARTIALLY') {
                paStatusClassName = 'is-active';
            } else {
                paStatusClassName = 'is-finished';
            }

            this.set('dfStatusClassName', dfStatusClassName);
            this.set('iaStatusClassName', iaStatusClassName);
            this.set('paStatusClassName', paStatusClassName);
        }
    });
});