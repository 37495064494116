define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/authentication/bankinfo', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _constants, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showSpinner: false,
        shouldDisableContinue: Ember.computed('optionSelected', 'model.validations.isTruelyInvalid', 'savingAccountInvalid', 'checkingAccountInvalid', function () {
            var optionSelected = this.get('optionSelected');
            if (!optionSelected) {
                return true;
            } else if (optionSelected === _constants.BANK_INFO.CREDIT_CARD) {
                return this.get('model.validations.isTruelyInvalid');
            } else if (optionSelected === _constants.BANK_INFO.SAVING_ACCOUNT) {
                return this.get('savingAccountInvalid');
            } else {
                return this.get('checkingAccountInvalid');
            }
        }),
        actions: {
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            validate: function validate() {
                var _this = this;

                var optionSelected = this.get('optionSelected');
                var dto = {};
                if (optionSelected === _constants.BANK_INFO.CREDIT_CARD && this.get('model.validations.isValid')) {
                    dto.creditCardAnswer = {
                        lastNumbers: this.get('model.lastFourNumber'),
                        expirationDate: this.get('model.expirationDate')
                    };
                } else if (optionSelected === _constants.BANK_INFO.SAVING_ACCOUNT) {
                    dto.accountNumber = (0, _commons.substringBefore)(this.get('selectionSavingAccount').contractRef, "*") + this.get('missingValueSavingAccount') + (0, _commons.substringAfterLast)(this.get('selectionSavingAccount').contractRef, "*");
                } else if (optionSelected === _constants.BANK_INFO.CHECKING_ACCOUNT) {
                    dto.accountNumber = (0, _commons.substringBefore)(this.get('selectionCheckingAccount').contractRef, "*") + this.get('missingValueCheckingAccount') + (0, _commons.substringAfterLast)(this.get('selectionCheckingAccount').contractRef, "*");
                } else {
                    this.send('displayAlertMessage', this.get('intl').t('errors.generic'), 'ERROR');
                    return;
                }

                this.set('didValidate', false);
                this.set('showSpinner', true);

                var veryWeak = Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.phones')) && Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.emails'));

                if (veryWeak) {
                    // Very weak scénario provides a new token : we need to authenticate
                    this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(dto), '/identification/veryweak').then(function () {
                        _this.send('handleSuccess', dto);
                        _this.transitionToRoute('accountcreation.finalization');
                    }).catch(function (reason) {
                        _this.get('currentUser').restoreSession();
                        _this.send('handleError', reason);
                    });
                } else {
                    Ember.$.post({
                        url: _constants.API_NAMESPACE + '/identification/check',
                        data: JSON.stringify(dto),
                        contentType: 'application/json'
                    }).then(function () {
                        _this.send('handleSuccess', dto);
                        _this.transitionToRoute('accountcreation.authentication.communicationchannel');
                    }).fail(function (reason) {
                        _this.send('handleError', reason);
                    });
                }
            },
            handleSuccess: function handleSuccess(dto) {
                var optionSelected = this.get('optionSelected');
                var authenticationanswers = this.get('session.data.authenticationanswers-dto');
                if (optionSelected === 'CREDIT_CARD') {
                    authenticationanswers.creditCardAnswer = dto.creditCardAnswer;
                } else if (optionSelected === 'SAVING_ACCOUNT') {
                    authenticationanswers.savingAccountNumber = dto.accountNumber;
                } else if (optionSelected === 'CHECKING_ACCOUNT') {
                    authenticationanswers.checkingAccountNumber = dto.accountNumber;
                }
                this.get('session').set('data.authenticationanswers-dto', authenticationanswers);
                this.get('session').set('data.otp_sending_attempt', this.get('otp_sending_attempt'));
                this.set('showSpinner', false);
                this.send('resetErrorMessage');
            },
            handleError: function handleError(reason) {
                var _this2 = this;

                this.set('showSpinner', false);
                if (reason.responseJSON.errors.message === 'key:errors.exception.BadChallengeEnrolmentIdentifiedException') {
                    if (reason.responseJSON.errors.attempts === 0) {
                        this.send('resetErrorMessage');
                        this.transitionToRoute('accountlocked');
                    } else {
                        var errorMessage = [this.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                            return _this2.get('intl').exists(value);
                        }, 'errors.generic'), { 'attempts': reason.responseJSON.errors.attempts }), this.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l1'), this.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l2'), this.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l3'), this.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l4')];
                        this.send('displayAlertMessage', errorMessage, 'ERROR');
                    }
                } else {
                    this.send('displayAlertMessage', this.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                        return _this2.get('intl').exists(value);
                    }, 'errors.generic')), 'ERROR');
                }
            }
        }
    });
});