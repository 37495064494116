define('fpl-fgdr-depositorweb-webapp/models/mycontact', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({
        ownerIdentity: (0, _emberCpValidations.validator)('presence', true),
        recipientIdentity: (0, _emberCpValidations.validator)('presence', true),
        'address.numAndWordingOfWay': (0, _emberCpValidations.validator)('presence', true),
        'address.postalCode': (0, _emberCpValidations.validator)('format', { regex: /^[a-z0-9]+$/i }),
        'address.city': (0, _emberCpValidations.validator)('presence', true),
        'address.country': (0, _emberCpValidations.validator)('presence', true)
    });

    exports.default = Ember.Object.extend(Validations, {
        validated: null,
        ownerIdentity: null,
        recipientIdentity: null,
        address: Ember.A(),
        mails: Ember.A(),
        phones: Ember.A(),
        sendCommunicationsByLetter: null
    });
});