define('fpl-fgdr-depositorweb-webapp/components/collapse-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        intl: Ember.inject.service(),
        reverse: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.schedule('afterRender', this, function () {
                _this.set('content', Ember.$(_this.get('elementToCollapse')));
                _this.set('rendered', true);
                _this.toggleCollapsed(0);
            });
        },


        collapsedChange: Ember.observer('collapsed', function () {
            if (this.get('rendered')) {
                this.toggleCollapsed(500);
            }
        }),

        toggleCollapsed: function toggleCollapsed(time) {
            if (this.get('collapsed')) {
                this.get('reverse') ? this.get('content').slideDown(time) : this.get('content').slideUp(time);
                this.set('title', this.get('intl').t('collapse-button.show'));
            } else {
                this.get('reverse') ? this.get('content').slideUp(time) : this.get('content').slideDown(time);
                this.set('title', this.get('intl').t('collapse-button.hide'));
            }
        },


        actions: {
            onClick: function onClick() {
                this.toggleProperty('collapsed');
            }
        }
    });
});