define('fpl-fgdr-depositorweb-webapp/controllers/resetpassword', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        showSpinner: false,
        actions: {
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            close: function close() {
                this.set('showErrorMessageDiv', false);
            },
            validate: function validate() {
                var _this = this;

                if (this.get('model.validations.isValid')) {
                    var deferred = Ember.RSVP.defer();
                    this.set('showSpinner', true);
                    var password = this.get('model.newPassword');
                    var locale = this.get('intl.locale').toLocaleString();
                    locale = locale.substr(0, locale.indexOf('-'));
                    var dto = {};
                    dto.password = password;
                    dto.locale = locale;
                    this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(dto), '/account/passwordChangeToken').then(function () {
                        var lastLoginDate = _this.get('session.data.authenticated.lastLoginDate');
                        if (lastLoginDate) {
                            var date = moment(lastLoginDate).format(_this.get('intl').t('application.formatDateTime.format_date'));
                            var hour = moment(lastLoginDate).format(_this.get('intl').t('application.formatDateTime.format_hour'));
                            var formattedDate = _this.get('intl').t('application.formatDateTime.formatted-date', {
                                'date': date,
                                'hour': hour
                            });
                            _this.send('displayAlertMessage', _this.get('intl').t('application.login.lastLoginDate') + formattedDate, 'INFO');
                        } else {
                            _this.send('displayAlertMessage', _this.get('intl').t('application.login.lastLoginDate') + _this.get('intl').t('application.login.firstLogin'), 'INFO');
                        }
                        _this.get('currentUser').updatefullName();
                        _this.set('showSpinner', false);
                        _this.get('currentUser').tunnelRedirection(deferred);
                    }).catch(function (reason) {
                        if (reason.responseJSON) {
                            _this.set('showErrorMessageDiv', true);
                        }
                        _this.set('showSpinner', false);
                    });

                    deferred.promise.then(function (value) {
                        _this.transitionToRoute(value);
                    });
                }
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('didValidate', false);
        }
    });
});