define('fpl-fgdr-depositorweb-webapp/controllers/updatepassword', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showSpinner: false,
        actions: {
            cancel: function cancel() {
                this.transitionToRoute('home');
            },
            close: function close() {
                this.set('showErrorMessageDiv', false);
            },
            validate: function validate() {
                var _this = this;

                this.set('didValidate', true);
                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var locale = this.get('intl.locale').toLocaleString();
                    locale = locale.substr(0, locale.indexOf('-'));
                    var payload = {
                        oldPassword: this.get('model.oldPassword'),
                        newPassword: this.get('model.newPassword'),
                        locale: locale
                    };
                    Ember.$.ajax({
                        context: this,
                        url: _constants.API_NAMESPACE + '/account/passwordChange',
                        type: 'POST',
                        data: JSON.stringify(payload),
                        contentType: 'application/json'
                    }).then(function () {
                        _this.send('displayAlertMessage', _this.get('intl').t('updatepassord.password-change-success'), 'SUCCESS');
                        _this.set('showSpinner', false);
                        _this.transitionToRoute('home');
                    }).catch(function (reason) {
                        if (reason.responseJSON) {
                            _this.set('showErrorMessageDiv', true);
                        }
                        _this.set('showSpinner', false);
                    });
                }
            }
        }
    });
});