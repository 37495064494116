define('fpl-fgdr-depositorweb-webapp/routes/mycontactinformation', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/models/mycontact', 'ember-copy'], function (exports, _authenticatedRouteMixin, _constants, _mycontact, _emberCopy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model() {
            var _this = this;

            return new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/contacts').then(function (response) {
                    var callback = _mycontact.default.create(Ember.getOwner(_this).ownerInjection(), {
                        ownerIdentity: response.ownerIdentity,
                        recipientIdentity: response.recipientIdentity,
                        address: response.address,
                        mails: _this.formatMails(response.mails),
                        phones: response.phones,
                        validated: response.validated,
                        communicationLanguage: response.communicationLanguage
                    });
                    resolve(callback);
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('copyModelAddress', (0, _emberCopy.copy)(model.get('address')), true);
            controller.set('copyModelMails', (0, _emberCopy.copy)(model.get('mails'), true));
            controller.set('copyModelPhones', (0, _emberCopy.copy)(model.get('phones'), true));
            controller.set('mciContent', this.get('intl').t('my-contact-information.content', { campaignMemberName: this.get('session.data.campaign-dto.memberName') }));
            controller.set('showErrorMessage', false);
            controller.set('showSuccessMessage', false);
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('inputs', Ember.A());
            }
        },
        formatMails: function formatMails(mails) {
            return mails.map(function (mail) {
                return { value: mail, confirm: mail };
            });
        },


        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});