define('fpl-fgdr-depositorweb-webapp/components/communication-row', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'npm:b64-to-blob'], function (exports, _constants, _npmB64ToBlob) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        showSpinner: false,
        init: function init() {
            this._super.apply(this, arguments);
            this.set('withBold', !this.get('com.read'));
            this.set('canDisplayLink', ['PHONE', 'OUTCALL'].indexOf(this.get('com.channel')) < 0);
            this.set('needDeliveryReceipt', this.get('com.channel') === 'DEPOSITOR_WEB_MESSAGE' && this.get('com.status') === 'SENT' && this.get('com.direction') === 'OUTGOING' && this.get('com.type') !== 'INITIAL_SITUATION_LETTER');
            this.set('canDisplayContent', this.get('canDisplayLink') && !this.get('needDeliveryReceipt'));
            this.set('canDisplayCompensationNotice', this.get('com.type') === 'INITIAL_SITUATION_LETTER' || this.get('com.type') === 'COMPENSATION_LETTER');
            this.set('isEmail', this.get('com.channel') === 'EMAIL');
            this.set('isNotReceived', this.get('com.status') === 'NOT_RECEIVED');
            this.set('showContent', !Ember.isEmpty(this.get('com.contents.outContent')) || !Ember.isEmpty(this.get('com.contents.processedContent')));
        },


        collapseContent: '',

        actions: {
            validate: function validate() {
                var context = this;
                Ember.$.post(_constants.API_NAMESPACE + '/communications/markCommunicationAsDelivered/' + this.get('com.id'), { comId: this.get('com.id') }).then(function () {
                    context.set('canDisplayContent', true);
                    context.set('needDeliveryReceipt', false);
                    context.set('com.status', 'RECEIVED');
                    context.send('showContent');
                });
            },
            showContent: function showContent() {
                if (this.get('canDisplayContent')) {
                    var comRow = Ember.$("#comContents-" + this.get('com.id'));
                    if (this.get('collapseContent') === '') {
                        var com = this;
                        if (!this.get('com.read')) {
                            Ember.$.post(_constants.API_NAMESPACE + '/communications/markCommunicationAsRead/' + this.get('com.id'), { comId: this.get('com.id') }).then(function () {
                                com.set('com.read', true);
                                com.set('collapseContent', 'in');
                                com.sendAction();
                            });
                            this.set('withBold', false);
                            Ember.$('html,body').animate({ scrollTop: comRow.offset().top }, 'slow');
                        } else {
                            this.set('collapseContent', 'in');
                        }
                    } else {
                        this.set('collapseContent', '');
                    }

                    if (this.get('collapseContent')) {
                        Ember.$('html,body').animate({ scrollTop: comRow.offset().top }, 'slow');
                    }
                }
            },
            downloadFile: function downloadFile(file) {
                var _this = this;

                if (file !== undefined) {
                    this.set('showSpinner', true);
                    Ember.$.ajax({
                        url: _constants.API_NAMESPACE + '/documents/file/' + file.id,
                        processData: false,
                        contentType: false,
                        type: 'GET'
                    }).then(function (data) {
                        var blob = (0, _npmB64ToBlob.default)(data, 'application/octet-stream');
                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.target = '_blank';
                        link.download = file.fileName;
                        var container = document.getElementById('download-container');
                        // adding and removing link in invisible download div in case multiple files can be downloaded
                        container.appendChild(link);
                        link.click();
                        container.removeChild(link);
                        _this.set('showSpinner', false);
                    }).catch(function () {
                        _this.set('showSpinner', false);
                    });
                }
            }
        }
    });
});