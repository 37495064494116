define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/authentication/bankinfo', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        model: function model() {
            return this.store.createRecord('creditcard-dto');
        },
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('PROVIDE_REGISTRATION_OTP_ROLE')) {
                this.transitionTo('home');
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('showErrorMessage', false);
            controller.set('didValidate', false);
            controller.set('memberName', this.get('session.data.campaign-dto.memberName'));
            var challenge = this.get('session.data.authenticationchallenge-dto');

            controller.set('optionSelected', null);

            // controller settings for credit card form
            if (!challenge.creditCardDeclared) {
                controller.set('shouldDisplayCreditCard', false);
            } else {
                controller.set('shouldDisplayCreditCard', true);
                controller.set('creditCardFirstNumbers', this.get('session.data.authenticationchallenge-dto.creditCardFirstNumbers'));
                // init selected bank info with CREDIT_CARD
                controller.set('optionSelected', _constants.BANK_INFO.CREDIT_CARD);
            }

            // controller settings for saving account form
            if (Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.savingAccounts'))) {
                controller.set('shouldDisplaySavingAccount', false);
            } else {
                controller.set('shouldDisplaySavingAccount', true);
                controller.set('memberName', this.get('session.data.campaign-dto.memberName'));
                controller.set('savingAccounts', this.get('session.data.authenticationchallenge-dto.savingAccounts'));
                controller.set('selectionSavingAccount', null);
                controller.set('missingValueSavingAccount', '');
                // init selected bank info with SAVING_ACCOUNT
                if (!controller.get('optionSelected')) {
                    controller.set('optionSelected', _constants.BANK_INFO.SAVING_ACCOUNT);
                }
            }

            // controller settings for checking account form
            if (Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.checkingAccounts'))) {
                controller.set('shouldDisplayCheckingAccount', false);
            } else {
                controller.set('shouldDisplayCheckingAccount', true);
                controller.set('showErrorMessage', false);
                controller.set('memberName', this.get('session.data.campaign-dto.memberName'));
                controller.set('checkingAccounts', this.get('session.data.authenticationchallenge-dto.checkingAccounts'));
                controller.set('selectionCheckingAccount', null);
                controller.set('missingValueCheckingAccount', '');
                // init selected bank info with CHECKING_ACCOUNT
                if (!controller.get('optionSelected')) {
                    controller.set('optionSelected', _constants.BANK_INFO.CHECKING_ACCOUNT);
                }
            }

            controller.set('savingAccountInvalid', true);
            controller.set('checkingAccountInvalid', true);
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('didValidate', false);
                controller.set('showErrorMessage', false);
                controller.get('model').rollbackAttributes();
                controller.set('otp_sending_attempt', 0);
            }
        }
    });
});