define('fpl-fgdr-depositorweb-webapp/routes/payment', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin', 'fpl-fgdr-depositorweb-webapp/services/constants', 'ember-copy'], function (exports, _authenticatedRouteMixin, _constants, _emberCopy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model() {
            return this.store.createRecord('paymentdetails-dto');
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('showErrorMessage', false);
            controller.set('showErrorMessageBic', false);
            controller.set('showErrorPaymentModeMessage', false);

            Ember.$.ajax({
                url: _constants.API_NAMESPACE + '/df/payment',
                type: 'GET',
                contentType: 'application/json'
            }).then(function (response) {
                controller.set('payment-dto', response);
                controller.set('paymentCopyBic', (0, _emberCopy.copy)(response.bic));
                controller.set('paymentCopyIban', (0, _emberCopy.copy)(response.iban));
                controller.set('paymentCopyAccountOwner', (0, _emberCopy.copy)(response.accountOwner));
                controller.set('paymentCopyCni', (0, _emberCopy.copy)(response.cni));
                controller.set('paymentCopyMode', (0, _emberCopy.copy)(setPaymentMode(response.paymentMode)));
                model.set('paymentMode', setPaymentMode(response.paymentMode));

                model.set('bic', response.bic);
                model.set('iban', response.iban);
                model.set('accountOwner', response.accountOwner);
                model.set('cni', response.cni);
                model.set('bicIbanZoneMandatory', response.bicIbanZoneMandatory);
                model.set('cniZoneMandatory', response.cniZoneMandatory);
                model.set('fgdr', response.fgdr);
                controller.set('onlyTransferAvailable', response.transferAvailable && !response.chequeAvailable);
                controller.set('onlyChequeAvailable', !response.transferAvailable && response.chequeAvailable);
                controller.set('chequeAndTransferAvailable', response.transferAvailable && response.chequeAvailable);
                controller.set('onlyTransferAvailableOrNotFGDR', response.transferAvailable && !response.chequeAvailable || !response.fgdr);
            });

            controller.set('didValidate', false);
        },


        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });


    function setPaymentMode(paymentMode) {
        if (!paymentMode || Ember.isEmpty(paymentMode) || paymentMode === 'NONE') {
            return "TRANSFER";
        } else {
            return paymentMode;
        }
    }
});