define('fpl-fgdr-depositorweb-webapp/components/transfer-block', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isTransfer: Ember.computed('model.paymentMode', function () {
            return this.get('model.paymentMode') === 'TRANSFER' || this.get('model.paymentMode') === 'PAYMENT_INSTRUCTION';
        }),
        didInsertElement: function didInsertElement() {
            Ember.$('#iban').mask('AAAA AAAA AAAA AAAA AAAA AAAA AAA');
        }
    });
});