define('fpl-fgdr-depositorweb-webapp/services/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Constant for identification STRENGTH
   *
   * @type {{STRONG: string, WEAK: string, FAILED: string}}
   */
  var STRENGTH = exports.STRENGTH = {
    STRONG: 'STRONG',
    WEAK: 'WEAK',
    VERY_WEAK: 'VERY_WEAK',
    FAILED: 'FAILED'
  };

  /**
   * Constant for sending's channel type
   *
   * @type {{SMS: string, EMAIL: string, OUTCALL: string}}
   */
  var OTP_CHANNEL = exports.OTP_CHANNEL = {
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    OUTCALL: 'OUTCALL'
  };

  var OTP_STUBMODE = exports.OTP_STUBMODE = '78ZDGA41';
  var OTP_STUBMODE_WEAK = exports.OTP_STUBMODE_WEAK = '78ZDGA42';

  /**
   * Constant for phone type
   *
   * @type {{MOBILE: string, FIXE: string}}
   */
  var PHONE_TYPE = exports.PHONE_TYPE = {
    MOBILE: 'MOBILE',
    FIXE: 'FIXE'
  };

  var QUERIES_RESPONSE = exports.QUERIES_RESPONSE = {
    FAILED: 'FAILED',
    VALIDATED: 'VALIDATED',
    INVALID_FILE_FORMAT: 'INVALID_FILE_FORMAT',
    VIRUS_ERROR: 'VIRUS_ERROR'
  };

  /**
   * Constant for validation of communication channel
   *
   * @type {{PHONE: string, EMAIL: string, NONE: string}}
   */
  var CHANNEL_TYPE = exports.CHANNEL_TYPE = {
    PHONE_NUMBER: 'PHONE_NUMBER',
    EMAIL: 'EMAIL',
    NONE: 'NONE'
  };

  var API_NAMESPACE = exports.API_NAMESPACE = '/api';

  /**
   * Cni file max size
   * Max file size in MB for the uploaded documents
   *
   * @type {number}
   */
  var FILE_MAX_SIZE = exports.FILE_MAX_SIZE = 5242880;

  /**
   * Constant for the size of a page
   *
   * @type {number}
   */
  var PAGE_SIZE = exports.PAGE_SIZE = 20;

  /**
   * RegEx for alphanumeric
   *
   * @type {RegExp}
   */
  var REG_EX_ALPHANUMERIC = exports.REG_EX_ALPHANUMERIC = new RegExp(/^[a-z0-9]+$/i);

  /**
   * Constants for options intl amount size.
   */
  var OPTIONS_INTL_AMOUNT = exports.OPTIONS_INTL_AMOUNT = {
    style: 'currency',
    currency: 'EUR'
  };

  var PIE_CHART_COLORS = exports.PIE_CHART_COLORS = ['#ff944d', '#e65c00', '#adadad', '#777777'];

  /**
   * Constant for validation of bank data
   *
   * @type {{CREDIT_CARD: string, SAVING_ACCOUNT: string, CHECKING_ACCOUNT: string}}
   */
  var BANK_INFO = exports.BANK_INFO = {
    CREDIT_CARD: 'CREDIT_CARD',
    SAVING_ACCOUNT: 'SAVING_ACCOUNT',
    CHECKING_ACCOUNT: 'CHECKING_ACCOUNT'
  };

  /**
   * Available languages
   */
  var LANGUAGES = exports.LANGUAGES = ['fr-fr', 'en-en', 'de-de', 'es-es', 'it-it', 'nl-nl'];

  /**
   * Default language
   */
  var DEFAULT_LANGUAGE = exports.DEFAULT_LANGUAGE = 'fr-fr';

  /**
   * Constant for communication forms
   *
   * @type {{CORRESPONDENCE_FORM: string, COMPLAINT_FORM: string}}
   */
  var COMMUNICATION_FORM = exports.COMMUNICATION_FORM = {
    CORRESPONDENCE_FORM: 'CORRESPONDENCE_FORM',
    COMPLAINT_FORM: 'COMPLAINT_FORM'
  };

  /**
   * Service for app constants.
   */
  exports.default = Ember.Service.extend({});
});