define('fpl-fgdr-depositorweb-webapp/models/finalization-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Data validation
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        password: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.accountcreation.authentication.finalization.password.not-empty-field'
        })],
        confirmation: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.accountcreation.authentication.finalization.confirmation.not-empty-field'
        }), (0, _emberCpValidations.validator)('confirmation', {
            on: 'password',
            messageKey: 'errors.accountcreation.authentication.finalization.confirmation.notIdentical'
        })],
        filename: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.bool('model.isStrongAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.filename.not-empty-file'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|pdf|PDF)$/,
            disabled: Ember.computed.bool('model.isStrongAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.filename.notValidFilename'
        }), (0, _emberCpValidations.validator)('filesize-validator', {
            inputIndex: 0
        })],
        prospectPhone: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectPhone.not-empty-field'
        })],
        prospectPhoneConfirmation: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectPhoneConfirmation.not-empty-field'
        }), (0, _emberCpValidations.validator)('confirmation', {
            on: 'prospectPhone',
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectPhoneConfirmation.notIdentical'
        })],
        prospectEmail: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectEmail.not-empty-field'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectEmail.bad-format'
        })],
        prospectEmailConfirmation: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectEmailConfirmation.not-empty-field'
        }), (0, _emberCpValidations.validator)('confirmation', {
            on: 'prospectEmail',
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.prospectEmailConfirmation.notIdentical'
        })],
        phoneEvidenceFile: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.phoneEvidenceFile.not-empty-file'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|pdf|PDF)$/,
            disabled: Ember.computed.not('model.isVeryWeakAuthentication').volatile(),
            messageKey: 'errors.accountcreation.authentication.finalization.phoneEvidenceFile.notValidFilename'
        }), (0, _emberCpValidations.validator)('filesize-validator', {
            inputIndex: 1
        })]
    });

    /**
     * Represents password model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        isStrongAuthentication: attr('boolean'),
        password: attr('string'),
        confirmation: attr('string'),
        filename: attr('string'),
        prospectPhone: attr('string'),
        prospectPhoneConfirmation: attr('string'),
        prospectEmail: attr('string'),
        prospectEmailConfirmation: attr('string'),
        phoneEvidenceFile: attr('string')
    });
});