define('fpl-fgdr-depositorweb-webapp/models/authenticationanswers-dto', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        dfId: attr('string'),
        identificationStrength: attr('string'),
        creditCardAnswer: attr('string'),
        savingAccountNumber: attr('string'),
        checkingAccountNumber: attr('string'),
        confirmedEmail: attr('string'),
        confirmedPhone: attr('string'),
        phoneValueSecured: attr('string')
    });
});