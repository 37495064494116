define('fpl-fgdr-depositorweb-webapp/models/strongidentificationrequest-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        telematicRef: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.hasReference').volatile(),
            messageKey: 'errors.accountcreation.identification.reference.mandatory-telematicref'
        }),
        nameOrDenominationOrSiren: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.hasReference').volatile(),
            messageKey: 'errors.accountcreation.identification.reference.mandatory-nom-denomination-siren'
        })
    });

    /**
     * Strong identification data model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        telematicRef: attr('string'),
        nameOrDenominationOrSiren: attr('string'),
        campaignRef: attr('string'),
        hasReference: attr('boolean')
    });
});