define('fpl-fgdr-depositorweb-webapp/controllers/selectcampaign', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        /**
         * return array with no duplicated category
         */
        campaignModelIsNotEmpty: Ember.computed.notEmpty('model'),

        actions: {
            validate: function validate() {
                this.get('session').set('data.campaign-dto', this.get('campaignSelected'));
                this.transitionToRoute('login');
            }
        }
    });
});