define('fpl-fgdr-depositorweb-webapp/components/credit-card', ['exports', 'fpl-fgdr-depositorweb-webapp/services/commons', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _commons, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        checked: false,
        creditCardStart: Ember.computed('creditCardFirstNumbers', function () {
            if (this.get('creditCardFirstNumbers')) {
                var cardFirstValue = this.get('creditCardFirstNumbers').padEnd(12, "X");
                return (0, _commons.separateBySpaceEveryFourCharacter)(cardFirstValue);
            } else {
                return '';
            }
        }),
        shouldDisableContinue: Ember.computed('model.validations.isTruelyInvalid', 'iKnowIt', function () {
            return this.get('model.validations.isTruelyInvalid') && this.get('iKnowIt');
        }),
        disableCreditCardFields: Ember.computed('optionSelected', function () {
            return this.get('optionSelected') !== _constants.BANK_INFO.CREDIT_CARD;
        }),

        // last four numbers validator
        shouldDisplayLastFournNumbersValidations: Ember.computed('disableCreditCardFields', 'model.lastFourNumber', 'didValidate', function () {
            return !this.get('disableCreditCardFields') && (this.get('model.lastFourNumber') || this.get('didValidate'));
        }),
        areLastFourNumberValid: Ember.computed.and('model.validations.attrs.lastFourNumber.isTruelyValid', 'shouldDisplayLastFournNumbersValidations'),
        hasLastFourNumberValidError: Ember.computed('model.validations.attrs.lastFourNumber.isTruelyValid', 'shouldDisplayLastFournNumbersValidations', function () {
            return this.get('model.validations.attrs.lastFourNumber.isTruelyInvalid') && this.get('shouldDisplayLastFournNumbersValidations');
        }),

        // expiration date validators
        shouldDisplayExpirationDateValidations: Ember.computed('disableCreditCardFields', 'model.expirationDate', 'didValidate', function () {
            return !this.get('disableCreditCardFields') && (this.get('model.expirationDate') || this.get('didValidate'));
        }),
        isExpirationDateValid: Ember.computed.and('model.validations.attrs.expirationDate.isTruelyValid', 'shouldDisplayLastFournNumbersValidations'),
        hasExpirationDateError: Ember.computed('model.validations.attrs.expirationDate.isTruelyValid', 'shouldDisplayExpirationDateValidations', function () {
            return this.get('model.validations.attrs.expirationDate.isTruelyInvalid') && this.get('shouldDisplayExpirationDateValidations');
        }),
        didRender: function didRender() {
            var radioButton = Ember.$('#radio-creditcard input');

            radioButton.click(function () {
                this.set('optionSelected', _constants.BANK_INFO.CREDIT_CARD);
            }.bind(this));

            radioButton.prop('checked', this.get('optionSelected') === _constants.BANK_INFO.CREDIT_CARD);
        },
        didInsertElement: function didInsertElement() {
            Ember.$('#expirationDate').mask('00/00');
        }
    });
});