define('fpl-fgdr-depositorweb-webapp/validators/filesize-validator', ['exports', 'ember-cp-validations/validators/base', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _base, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FilesizeValidator = _base.default.extend({
        intl: Ember.inject.service(),

        validate: function validate(value, options) {
            if (Ember.isEmpty(value)) {
                return true;
            }

            // Check max file size is not reached
            var uploadedFile = Ember.$(":file")[options.inputIndex].files[0];
            if (uploadedFile !== undefined && uploadedFile.size > _constants.FILE_MAX_SIZE) {
                return this.get('intl').t('errors.file.excededSize');
            }

            return true;
        }
    });

    exports.default = FilesizeValidator;
});