define('fpl-fgdr-depositorweb-webapp/components/inputs/s-input', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field'], function (exports, _inputField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Simple Input.
     *
     * ```hbs
     * ie : {{inputs/s-input id='identifier' type='text'value=model.attribute}}
     * ```
     */
    var InputComponent = _inputField.default.extend({
        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],
        type: 'text',
        class: 'form-control',
        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid', 'shouldDisplayValidations').readOnly(),
        shouldDisplayValidations: Ember.computed('didValidate', 'hasContent', 'shouldValidate', function () {
            if (typeof this.get('shouldValidate') === 'boolean') {
                return (this.get('didValidate') || this.get('hasContent')) && this.get('shouldValidate');
            } else {
                return this.get('didValidate') || this.get('hasContent');
            }
        }).readOnly(),
        showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
            if (!this.get('inputId')) {
                this.set('inputId', 'input-' + this.get('inputSequence').next());
            }

            var name = this.get('name');
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + name));

            // in case attribute name is not the same in model and validator
            if (this.get('validatorModel') && this.get('validatorAttributeName')) {
                var validatorAttributeName = this.get('validatorAttributeName');
                Ember.defineProperty(this, 'validation', Ember.computed.readOnly('validatorModel.validations.attrs.' + validatorAttributeName));
            } else {
                Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
            }
        }
    });

    InputComponent.reopenClass({
        positionalParams: ['model', 'validatorModel', 'validatorAttributeName']
    });

    exports.default = InputComponent;
});