define('fpl-fgdr-depositorweb-webapp/models/communication-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Data validation
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        message: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.bool('model.document').volatile(),
            messageKey: 'errors.sendmessage.emptyFields-error'
        }), (0, _emberCpValidations.validator)('length', {
            max: 1000,
            messageKey: 'errors.sendmessage.maxLength-error'
        })],
        document: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.bool('model.message').volatile(),
            messageKey: 'errors.sendmessage.emptyFields-error'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|bmp|BMP|doc|DOC|docx|DOCX|pdf|PDF)$/,
            allowBlank: true,
            messageKey: 'errors.sendmessage.notValidFilename-error'
        }), (0, _emberCpValidations.validator)('filesize-validator', {
            inputIndex: 0
        })]
    });

    /**
     * Represents communication model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        message: attr('string'),
        document: attr('string')
    });
});