define('fpl-fgdr-depositorweb-webapp/routes/home', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin', 'fpl-fgdr-depositorweb-webapp/services/constants', 'npm:b64-to-blob'], function (exports, _authenticatedRouteMixin, _constants, _npmB64ToBlob) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model() {
            var _this = this;

            var breadcrumbtrail = new Promise(function (resolve, reject) {
                Ember.$.get(_constants.API_NAMESPACE + '/df/getProgress').then(function (response) {
                    return resolve(response);
                }, function (error) {
                    return reject(error);
                });
            });

            var lastLetter = new Promise(function (resolve) {
                Ember.$.ajax({
                    type: 'GET',
                    url: _constants.API_NAMESPACE + '/documents/lastcl',
                    dataType: 'json',
                    contentType: 'application/json; charset=utf-8'
                }).then(function (response) {
                    resolve(response);
                }, function () {
                    return resolve('');
                });
            });

            var editorialContent = new Promise(function (resolve) {
                var campaignRef = _this.get('session.data.campaign-dto.campaignRef');
                Ember.$.get(_constants.API_NAMESPACE + '/campaigns/' + campaignRef + '/editorialcontent').then(function (response) {
                    return resolve(response);
                });
            });

            return Promise.all([breadcrumbtrail, lastLetter, editorialContent]).then(function (values) {
                return {
                    'breadcrumbtrail': values[0],
                    'lastLetter': values[1],
                    'editorialContent': values[2]
                };
            });
        },

        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            },
            downloadCompensationLetter: function downloadCompensationLetter(fileName) {
                if (fileName !== undefined) {
                    Ember.$.ajax({
                        url: _constants.API_NAMESPACE + '/documents/lastcl/data',
                        processData: false,
                        contentType: false,
                        type: 'GET'
                    }).then(function (data) {
                        var blob = (0, _npmB64ToBlob.default)(data, 'application/octet-stream');
                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.target = '_blank';
                        link.download = fileName;
                        document.getElementById('download-container').appendChild(link);
                        link.click();
                    });
                }
            }
        }
    });
});