define('fpl-fgdr-depositorweb-webapp/routes/contact', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.createRecord('contactrequest-dto', {
                naturalPerson: true,
                isAuthenticated: this.get('currentUser').hasAccess("CONNECTED_ROLE")
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            if (this.get('currentUser.user.refDto.dfId') !== undefined) {
                controller.set('userRef', this.get('currentUser.user.refDto.dfId'));
            }
            controller.set('didValidate', false);
            controller.set('phone', this.get('currentUser.session.data.campaign-dto.address.phone'));
        },

        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});