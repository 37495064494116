define('fpl-fgdr-depositorweb-webapp/mixins/login-success-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            if (this.get('session.isAuthenticated')) {
                //TODO : Add redirection to mycontactinformation access is finished when the contact isn't confirmed
                if (!this.get('currentUser.paymentInformationConfirmed')) {
                    this.transitionTo('payment');
                } else {
                    transition.abort();
                    this.transitionTo('home');
                }
            }
        }
    });
});