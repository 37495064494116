define('fpl-fgdr-depositorweb-webapp/components/inputs/input-file-upload', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field'], function (exports, _inputField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var InputComponent = _inputField.default.extend({
        type: 'file',
        accept: '.jpg, .jpeg, .png, .bmp, .gif, .pdf, .doc, .docx, .xlsx, .xls',
        class: 'form-control',
        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],

        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        shouldDisplayValidations: Ember.computed('didValidate', 'hasContent', function () {
            return this.get('didValidate') || this.get('hasContent');
        }).readOnly(),

        isValid: Ember.computed('didValidate', 'name', 'shouldDisplayValidations', 'validation', function () {
            if (this.get('didValidate')) {
                // In case of retry, the uploaded file is lost (fake path is present but file is lost)
                if (document.getElementsByName(this.get('name')).length > 0) {
                    return this.get('validation.isValid');
                } else {
                    return false;
                }
            }
            return this.get('shouldDisplayValidations') && this.get('validation.isValid');
        }).readOnly(),
        showErrorMessage: Ember.computed('shouldDisplayValidations', 'isValid', function () {
            return this.get('shouldDisplayValidations') && !this.get('isValid');
        }).readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
            if (!this.get('inputId')) {
                this.set('inputId', 'input-' + this.get('inputSequence').next());
            }

            var name = this.get('name');
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + name));
            Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
        }
    });

    InputComponent.reopenClass({
        positionalParams: ['model']
    });

    exports.default = InputComponent;
});