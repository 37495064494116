define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/conditionsvalidation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        shouldDisableContinue: Ember.computed.not('model.checked'),
        actions: {
            printGeneralsCondtion: function printGeneralsCondtion() {
                Ember.$(document).ready(function () {
                    window.print();
                });
            },
            validate: function validate() {
                if (!this.get('model.checked')) {
                    this.set('showErrorMessage', true);
                } else {
                    if (!this.get('session.data.authenticationanswers-dto')) {
                        this.get('session').set('data.authenticationanswers-dto', {});
                    }
                    this.get('session').set('data.authenticationanswers-dto.checkingAccountNumber', '');
                    this.get('session').set('data.authenticationanswers-dto.confirmedEmail', '');
                    this.get('session').set('data.authenticationanswers-dto.creditCardAnswer', {});
                    this.get('session').set('data.authenticationanswers-dto.dfTelematicRef', '');
                    this.get('session').set('data.authenticationanswers-dto.identificationStrength', '');
                    this.get('session').set('data.authenticationanswers-dto.phoneValueSecured', '');
                    this.get('session').set('data.authenticationanswers-dto.savingAccountNumber', '');
                    this.transitionToRoute('accountcreation.identification');
                }
            },
            cancel: function cancel() {
                this.transitionToRoute('login');
            }
        }
    });
});