define('fpl-fgdr-depositorweb-webapp/components/checking-account', ['exports', 'fpl-fgdr-depositorweb-webapp/services/commons', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _commons, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        selection: null,
        hasError: Ember.computed('missingValue', 'selection', 'disableCheckingAccountFields', function () {
            var account = this.get('selection');
            if (account) {
                var missingDigitsInputWidth = (account.contractRef.match(/\*/g) || []).length;
                return this.get('missingValue') && (this.get('missingValue').length !== missingDigitsInputWidth || !_constants.REG_EX_ALPHANUMERIC.test(this.get('missingValue'))) && !this.get('disableCheckingAccountFields');
            } else {
                return false;
            }
        }),
        isValid: Ember.computed('missingValue', 'selection', 'disableCheckingAccountFields', function () {
            var account = this.get('selection');
            if (account) {
                var missingDigitsInputWidth = (account.contractRef.match(/\*/g) || []).length;
                return this.get('missingValue').length === missingDigitsInputWidth && _constants.REG_EX_ALPHANUMERIC.test(this.get('missingValue')) && !this.get('disableCheckingAccountFields');
            } else {
                return false;
            }
        }),
        shouldDisableContinue: Ember.observer('hasError', 'missingValue', function () {
            var result = this.get('hasError') || Ember.isEmpty(this.get('missingValue'));
            this.set('checkingAccountInvalid', result);
            return result;
        }),
        disableCheckingAccountFields: Ember.computed('optionSelected', function () {
            return this.get('optionSelected') !== _constants.BANK_INFO.CHECKING_ACCOUNT;
        }),
        inputStyle: Ember.computed('missingDigitsInputWidth', function () {
            var width = this.get('missingDigitsInputWidth');
            return Ember.String.htmlSafe('width:' + width + 'px;');
        }),
        actions: {
            onSelectedAccount: function onSelectedAccount(account) {
                if (account) {
                    fillForm.bind(this)(account);
                } else {
                    this.set('firstDigits', '');
                    this.set('lastDigits', '');
                    this.set('missingDigitsCount', '');
                    this.set('missingDigitsPlaceholder', '');
                    this.set('missingDigitsInputWidth', '');
                }
            }
        },
        didRender: function didRender() {
            var radioButton = Ember.$('#radio-checkingaccount input');

            radioButton.click(function () {
                this.set('optionSelected', _constants.BANK_INFO.CHECKING_ACCOUNT);
            }.bind(this));

            radioButton.prop('checked', this.get('optionSelected') === _constants.BANK_INFO.CHECKING_ACCOUNT);

            // if sent information are invalid, first and last digits should be redisplayed in inputs
            var account = this.get('selection');
            if (account) {
                fillForm.bind(this)(account);
            }
        }
    });


    function fillForm(account) {
        var firstDigits = (0, _commons.substringBefore)(account.contractRef, '*');
        this.set('firstDigits', (0, _commons.separateBySpaceEveryFourCharacter)(firstDigits));
        this.set('lastDigits', (0, _commons.substringAfterLast)(account.contractRef, '*'));
        this.set('missingDigitsCount', (0, _commons.getMissingDigitsCount)(account.contractRef));
        this.set('missingDigitsPlaceholder', '*'.repeat(this.get('missingDigitsCount')));
        this.set('missingDigitsInputWidth', (0, _commons.getMissingDigitsInputWidth)(account.contractRef, this.get('firstDigits'), this.get('lastDigits')));
    }
});