define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/authentication/index', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        beforeModel: function beforeModel() {
            this.replaceWith('accountcreation.identification.reference');
        }
    });
});