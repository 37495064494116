define('fpl-fgdr-depositorweb-webapp/controllers/loginotp', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),
        intl: Ember.inject.service(),
        subOtpToken: _constants.OTP_STUBMODE,
        showSpinner: false,
        hasError: false,
        hasEmail: Ember.computed('emailOptions', function () {
            return !Ember.isEmpty(this.get('emailOptions'));
        }),
        hasPhones: Ember.computed('phoneOptions', function () {
            return !Ember.isEmpty(this.get('phoneOptions'));
        }),
        shouldDisableContinue: Ember.computed('value', function () {
            return Ember.isEmpty(this.get('value'));
        }),
        actions: {
            validateEmail: function validateEmail() {
                this.sendOtp(this.get('emailSelected'));
            },
            validatePhone: function validatePhone() {
                this.sendOtp(this.get('phoneSelected'));
            },
            confirmationOtp: function confirmationOtp() {
                var _this = this;

                if (this.get('value')) {
                    this.set('hasError', false);
                    var deferred = Ember.RSVP.defer();
                    this.set('showSpinner', true);
                    this.get('session').authenticate('authenticator:jwt-custom', this.get('value'), '/otp/validatelogin').then(function () {
                        _this.send('resetMessage');
                        var lastLoginDate = _this.get('session.data.authenticated.lastLoginDate');
                        if (lastLoginDate) {
                            var date = moment(lastLoginDate).format(_this.get('intl').t('application.formatDateTime.format_date'));
                            var hour = moment(lastLoginDate).format(_this.get('intl').t('application.formatDateTime.format_hour'));
                            var formattedDate = _this.get('intl').t('application.formatDateTime.formatted-date', {
                                'date': date,
                                'hour': hour
                            });
                            _this.send('displayAlertMessage', _this.get('intl').t('application.login.lastLoginDate') + formattedDate, 'INFO');
                        } else {
                            _this.send('displayAlertMessage', _this.get('intl').t('application.login.lastLoginDate') + _this.get('intl').t('application.login.firstLogin'), 'INFO');
                        }
                        _this.get('currentUser').updatefullName();
                        _this.set('showSpinner', false);
                        _this.get('currentUser').tunnelRedirection(deferred);
                    }).catch(function (reason) {
                        if (reason.responseJSON && reason.responseJSON.errors.message === "key:errors.exception.AccountLockedException") {
                            _this.set('showSpinner', false);
                            _this.set('isBlocked', true);
                            _this.transitionToRoute('accountlocked');
                        } else {
                            _this.send('displayAlertMessage', _this.get('intl').t('errors.accountcreation.authentication.otp.confirmation-code-error'), 'ERROR');
                            _this.set('showSpinner', false);
                            _this.get('currentUser').restoreSession();
                        }
                    });

                    deferred.promise.then(function (value) {
                        _this.set('formValid', true);
                        _this.transitionToRoute(value);
                    });
                } else {
                    this.set('hasError', true);
                }
            },
            back: function back() {
                // When you leave the page, you are disconnected
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            }
        },
        sendOtp: function sendOtp(dataOtp) {
            var _this2 = this;

            this.set('showSpinner', true);
            Ember.$.post({
                url: _constants.API_NAMESPACE + '/otp/requestlogin',
                data: dataOtp,
                contentType: 'application/json'
            }).then(function () {
                _this2.set('otpSent', true);
                _this2.set('showSpinner', false);
            }).fail(function () {
                _this2.send('displayAlertMessage', _this2.get('intl').t('errors.generic'), 'ERROR');
                _this2.set('showSpinner', false);
            });
        },
        init: function init() {
            this._super.apply(this, arguments);
        }
    });
});