define('fpl-fgdr-depositorweb-webapp/models/otprequest-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        campaignRef: (0, _emberCpValidations.validator)('presence', true),
        media: (0, _emberCpValidations.validator)('presence', true),
        address: (0, _emberCpValidations.validator)('presence', true),
        otp: (0, _emberCpValidations.validator)('presence', true),
        fullName: (0, _emberCpValidations.validator)('presence', true),
        telematicRef: (0, _emberCpValidations.validator)('presence', true)
    });

    /**
     * Otp request data model
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        campaignRef: attr('string'),
        media: attr('string'),
        address: attr('string'),
        otp: attr('string'),
        fullName: attr('string'),
        telematicRef: attr('string')
    });
});