define('fpl-fgdr-depositorweb-webapp/components/inputs/input-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        intl: Ember.inject.service(),
        tagName: '',
        minItems: 1,
        maxItems: 20,
        disabled: false,
        addButtonLabel: '',
        removeButtonLabel: '',
        buttonClass: 'col-xs-12 col-sm-3',
        buttonAddClass: 'btn btn-primary',
        buttonRemoveClass: 'btn btn-default',
        itemsLenghtChange: Ember.observer('items.length', function () {
            this.updateButtonDisplay();
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.updateButtonDisplay();
            var addButtonLabel = Ember.isEmpty(this.get('addButtonLabel')) ? this.get('intl').t('input-list.add-button') : this.get('addButtonLabel');
            var removeButtonLabel = Ember.isEmpty(this.get('removeButtonLabel')) ? this.get('intl').t('input-list.remove-button') : this.get('removeButtonLabel');
            this.set('addButtonLabel', addButtonLabel);
            this.set('removeButtonLabel', removeButtonLabel);
            if (Ember.isEmpty(this.get('items'))) {
                this.addItem();
            }
        },
        updateButtonDisplay: function updateButtonDisplay() {
            this.set('hasMaxItems', this.get('items.length') >= this.get('maxItems'));
            this.set('hasMinItems', this.get('items.length') <= this.get('minItems'));
        },
        addItem: function addItem() {
            this.get('items').pushObject({});
        },
        removeItem: function removeItem(index) {
            this.set('items', this.get('items').filter(function (item, indexItem) {
                return indexItem !== index;
            }));
        },


        actions: {
            add: function add() {
                this.addItem();
            },
            remove: function remove(index) {
                this.removeItem(index);
            }
        }
    });
});