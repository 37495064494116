define('fpl-fgdr-depositorweb-webapp/controllers/forgotpasswordmails', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showSpinner: false,
        actions: {
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            validate: function validate() {
                var _this = this;

                this.set('showSpinner', true);
                var hash = this.get('emailSelected').hash;
                Ember.$.ajax({
                    context: this,
                    url: _constants.API_NAMESPACE + '/otp/requestloginforgotpassword',
                    type: 'POST',
                    contentType: 'application/json',
                    data: hash
                }).then(function () {
                    _this.set('showSpinner', false);
                    _this.transitionToRoute('forgotpasswordotp');
                }).catch(function () {
                    _this.set('showSpinner', false);
                });
            }
        }
    });
});