define('fpl-fgdr-depositorweb-webapp/routes/resetpassword', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('CREATE_NEW_PASSWORD_ROLE')) {
                this.transitionTo('home');
            }
        },
        model: function model() {
            return this.store.createRecord('resetpassword', {
                hasReference: true
            });
        }
    });
});