define('fpl-fgdr-depositorweb-webapp/models/paymentdetails-dto', ['exports', 'ember-cp-validations', 'ember-data'], function (exports, _emberCpValidations, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    var Validations = (0, _emberCpValidations.buildValidations)({
        paymentMode: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'errors.paymentDetails.notValidPaymentMode'
        })],
        bic: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE' || !this.get('model.bicIbanZoneMandatory');
            }).volatile(),
            messageKey: 'errors.paymentDetails.notValidBIC',
            dependentKeys: ['model.paymentMode']
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^([a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/,
            allowBlank: true,
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE';
            }).volatile(),
            messageKey: 'errors.paymentDetails.notValidBIC',
            dependentKeys: ['model.paymentMode']
        })],
        iban: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE' || !this.get('model.bicIbanZoneMandatory');
            }).volatile(),
            messageKey: 'errors.paymentDetails.notValidIBAN',
            dependentKeys: ['model.paymentMode']
        }), (0, _emberCpValidations.validator)('iban-validator', {
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE';
            }).volatile()
        })],
        accountOwner: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE' || !this.get('model.bicIbanZoneMandatory');
            }).volatile(),
            messageKey: 'errors.paymentDetails.notValidOwner',
            dependentKeys: ['model.paymentMode']
        })],
        cni: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.paymentMode', function () {
                return this.get('model.paymentMode') === 'CHEQUE' || !this.get('model.cniZoneMandatory') || this.get('model.fgdr');
            }).volatile(),
            messageKey: 'errors.paymentDetails.notValidCNI',
            dependentKeys: ['model.paymentMode']
        })]
    });

    /**
     * DTO for Payment mode update
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        beneficiaryIdentity: attr('string'),
        paymentMode: attr('string'),
        bic: attr('string'),
        iban: attr('string'),
        accountOwner: attr('string'),
        cni: attr('string'),
        additionalInfo: attr('string'),
        validated: attr('boolean'),
        bicIbanZoneMandatory: attr('boolean'),
        cniZoneMandatory: attr('boolean'),
        fgdr: attr('boolean'),
        dgsName: attr('string')
    });
});