define('fpl-fgdr-depositorweb-webapp/components/inputs/p-select', ['exports', 'lodash', 'fpl-fgdr-depositorweb-webapp/components/power-select'], function (exports, _lodash, _powerSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     Power select input for auto complete selection.
    
     ```hbs
     {{#inputs/p-select value=phone.type
                        options=phoneTypes
                        field='value' as |phoneType|}}
     {{phoneType.label}}
     {{/inputs/p-select}}
     ```
     */
    var PowerSelectComponent = _powerSelect.default.extend({
        intl: Ember.inject.service(),
        triggerClass: 'form-control',
        allowClear: true,
        searchEnabled: false,
        defaultOptionSelected: false,

        optionChanged: Ember.observer('options', function () {
            this.selectDefaultValue();
        }),
        init: function init() {
            var _this = this;

            if (Ember.isEmpty(this.get('onchange'))) {
                this.set('onchange', this.defaultOnchange.bind(this));
            }
            this.set('onclose', this.onClose.bind(this));
            this.set('searchMessage', this.get('intl').t('p-select.search'));
            this.set('searchPlaceholder', this.get('intl').t('p-select.search'));
            this.set('loadingMessage', this.get('intl').t('p-select.loading'));
            this.set('noMatchesMessage', this.get('intl').t('p-select.no-results'));
            Ember.run.schedule('afterRender', this, function () {
                _this.selectDefaultValue();
            });
            this._super.apply(this, arguments);
        },
        defaultOnchange: function defaultOnchange(selected) {
            this.set('selected', selected);
            if (Ember.isEmpty(selected)) {
                this.set('value', null);
            } else {
                this.set('value', Ember.isEmpty(this.get('field')) ? selected : Ember.get(selected, this.get('field')));
            }
            if (!Ember.isEmpty(this.onSelected)) {
                this.onSelected(selected);
            }
        },
        onClose: function onClose() {
            if (!Ember.isEmpty(this.search)) {
                this.search("");
            }
        },
        selectDefaultValue: function selectDefaultValue() {
            var _this2 = this;

            if (!Ember.isEmpty(this.get('value'))) {
                this.set('selected', _lodash.default.find(this.get('options'), function (option) {
                    return _this2.get('value') === option || _this2.get('field') && _this2.get('value') === Ember.get(option, _this2.get('field'));
                }));
            }
            if (Ember.isEmpty(this.get('value')) && this.get('defaultOptionSelected')) {
                var firstOption = _lodash.default.first(this.get('options'));
                this.set('selected', firstOption);
                this.set('value', Ember.isEmpty(this.get('field')) || !firstOption ? firstOption : Ember.get(firstOption, this.get('field')));
                if (!Ember.isEmpty(this.onSelected)) {
                    this.onSelected(this.get('selected'));
                }
            }
        }
    });

    exports.default = PowerSelectComponent;
});