define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/identification/identity', ['exports', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        showSpinner: false,
        actions: {
            weakAuthentication: function weakAuthentication() {
                var _this = this;

                if (this.get('model.validations.isTruelyInvalid')) {
                    this.set('showErrorMessage', true);
                } else {
                    this.set('model.campaignRef', this.get('session.data.campaign-dto').campaignRef);
                    this.set('showSpinner', true);
                    this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(this.get('model').serialize()), '/identification/weak').then(function () {
                        _this.get('session').set('data.authenticationchallenge-dto', _this.get('session.data.authenticated.data'));
                        var authenticationanswers = (0, _commons.initAuthenticationanswersModel)();
                        _this.get('session').set('data.authenticationanswers-dto', authenticationanswers);
                        _this.set('showSpinner', false);
                        _this.get('currentUser').load(function () {
                            _this.transitionToRoute('accountcreation.authentication.bankinfo');
                        });
                    }).catch(function (reason) {
                        _this.set('showSpinner', false);
                        if (reason.responseJSON.errors.message === "key:errors.exception.IdentificationAccountNotEligibleException") {
                            _this.transitionToRoute('accountcreation.noteligible');
                        } else {
                            _this.send('displayAlertMessage', _this.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                                return _this.get('intl').exists(value);
                            }, 'errors.exception.AuthenticationException')), 'ERROR');
                        }
                    });
                }
            },
            cancel: function cancel() {
                this.transitionToRoute('login');
            }
        }
    });
});