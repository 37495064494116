define('fpl-fgdr-depositorweb-webapp/routes/forgotpasswordotp', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('CONSULT_LIST_COMMUNICATION_AND_SEND_OTP_ROLE')) {
                this.transitionTo('home');
            }
        },
        model: function model() {
            return this.store.createRecord('otpcode');
        }
    });
});