define('fpl-fgdr-depositorweb-webapp/components/pie-chart', ['exports', 'chart', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _chart, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        intl: Ember.inject.service(),
        classNames: ['col-xs-12', 'col-sm-6', 'data-test-piechart'],
        init: function init() {
            this._super.apply(this, arguments);
            this.set('colorSet', _constants.PIE_CHART_COLORS);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var canvas = this.element.querySelector('#' + this.elementId + ' canvas');
            var intl = this.get('intl');
            _chart.default.pluginService.register({
                beforeDraw: this.drawMiddleText
            });

            var options = {
                elements: {
                    center: {
                        text: this.get('middleLabel'),
                        color: '#6B7275',
                        fontStyle: 'latoregular',
                        sidePadding: 10
                    }
                },
                legend: {
                    display: false,
                    labels: {
                        filter: function filter(item, chart) {
                            var value = chart.datasets[0].data[item.index];
                            return value != undefined && value != 0;
                        }
                    }
                },
                cutoutPercentage: 75,
                responsive: false,
                title: {
                    display: true,
                    position: 'top',
                    text: this.get('title'),
                    fontSize: 17,
                    padding: 15
                },
                tooltips: {
                    bodyFontSize: 10,
                    callbacks: {
                        label: function label(tooltipItem, chartData) {
                            return chartData.labels[tooltipItem.index];
                        }
                    }
                },
                legendCallback: function legendCallback(chart) {
                    var res = '<ul>';
                    for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
                        var value = chart.data.datasets[0].data[i];
                        if (value !== undefined && value !== 0) {
                            res += '<li class="homepage-chart-legend"> <span style="background-color:' + chart.data.datasets[0].backgroundColor[i] + ';"> </span>' + chart.data.labels[i] + ' </li>';
                        }
                    }
                    res += '</ul>';
                    return res;
                }
            };
            var datasets = this.get('data');
            this.set('labels', this.get('labels').map(function (label, i) {
                return label + ' : ' + intl.formatNumber(datasets[i], _constants.OPTIONS_INTL_AMOUNT);
            }));

            var myChart = new _chart.default(canvas, {
                type: 'doughnut',
                data: {
                    labels: this.get('labels'),
                    datasets: [{
                        data: this.get('data'),
                        borderAlign: 'inner',
                        backgroundColor: this.colorSet,
                        borderColor: this.colorSet,
                        borderWidth: 1
                    }]
                },
                options: options
            });

            this.element.querySelector('#js-legend').innerHTML = myChart.generateLegend();
        },
        drawMiddleText: function drawMiddleText(chart) {
            if (chart.config.options.elements.center) {
                var ctx = chart.chart.ctx;
                var padding = 10;
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || 'Arial';
                var txt = centerConfig.text;
                var color = centerConfig.color || '#000';
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2);
                ctx.font = '34px ' + fontStyle;

                var stringWidth = 0;
                txt.forEach(function (item) {
                    stringWidth = Math.max(ctx.measureText(item).width, stringWidth);
                });
                var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = chart.innerRadius * 2;
                var fontSizeToUse = Math.min(newFontSize, elementHeight);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = fontSizeToUse + 'px ' + fontStyle;
                ctx.fillStyle = color;

                //Draw text in center
                txt.forEach(function (item, index) {
                    ctx.fillText(item, centerX, centerY + ((index - txt.length / 2) * fontSizeToUse + padding * index));
                });
            }
        }
    });
});