define('fpl-fgdr-depositorweb-webapp/components/communication-list', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['communicationList', 'col-xs-12'],

        init: function init() {
            this._super.apply(this, arguments);
            this.set('canShowMore', this.get('endIndex') < this.get('maxResults'));
        },


        actions: {
            showMore: function showMore() {
                var _this = this;

                this.set('endIndex', this.get('endIndex') + this.get('size'));
                Ember.$.get(_constants.API_NAMESPACE + '/communications/' + this.get('startIndex') + '/' + this.get('endIndex')).then(function (response) {
                    _this.set('coms', response.communicationDtos);
                    _this.set('maxResults', response.maxResults);
                    _this.set('canShowMore', _this.get('endIndex') < response.maxResults);
                });
            }
        }
    });
});