define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/finalization', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _constants, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        showSpinner: false,
        isRetry: false,
        inputs: Ember.A(),
        hasInvalidFields: Ember.computed('model.validations.isTruelyInvalid', function () {
            if (this.get('currentUser.user.identificationStrength') === _constants.STRENGTH.VERY_WEAK) {
                var telInput = document.getElementById('phone');
                var telConfirmation = document.getElementById('phoneConfirmation');
                return this.get('model.validations.isTruelyInvalid') || !(0, _commons.isTelValidFormat)(telInput) || !(0, _commons.isTelValidFormat)(telConfirmation);
            } else {
                return this.get('model.validations.isTruelyInvalid');
            }
        }),
        shouldDisableContinue: Ember.computed('hasInvalidFields', 'isRetry', function () {
            if (this.get('isRetry') && this.get('currentUser.user.identificationStrength') !== _constants.STRENGTH.STRONG) {
                // Check if cni and phone files are valid (in case of retry, uploaded files are lost)
                var file = Ember.$(":file")[0];
                if (this.get('currentUser.user.identificationStrength') === _constants.STRENGTH.VERY_WEAK) {
                    var phoneFile = Ember.$(":file")[1];
                    return this.get('hasInvalidFields') || Ember.isEmpty(file.value) || Ember.isEmpty(phoneFile.value);
                } else {
                    return this.get('hasInvalidFields') || Ember.isEmpty(file.value);
                }
            }
            return this.get('hasInvalidFields');
        }),
        actions: {
            finalization: function finalization() {
                var _this = this;

                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    this.set('isRetry', false);
                    var deferred = Ember.RSVP.defer();
                    this.send('resetMessage');

                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;

                    var fd = new FormData();
                    var dto = {};
                    dto.confirmedEmail = this.get('session.data.authenticationanswers-dto').confirmedEmail;
                    dto.phoneValueSecured = this.get('session.data.authenticationanswers-dto').phoneValueSecured;
                    dto.password = this.get('model.password');
                    var locale = this.get('intl.locale').toLocaleString();
                    dto.locale = locale.substr(0, locale.indexOf('-'));

                    var identificationStrength = this.get('currentUser.user.identificationStrength');
                    // Weak identification - Upload identity document
                    if (identificationStrength === _constants.STRENGTH.WEAK || identificationStrength === _constants.STRENGTH.VERY_WEAK) {
                        var cniFile = Ember.$(":file")[0].files[0];
                        dto.cniFileName = cniFile.name;
                        dto.cniFileSize = cniFile.size;
                        dto.cniMimeType = cniFile.type;
                        fd.append('cniStream', cniFile);

                        if (identificationStrength === _constants.STRENGTH.VERY_WEAK) {
                            var phoneEvidenceFile = Ember.$(":file")[1].files[0];
                            dto.phoneEvidenceFileName = phoneEvidenceFile.name;
                            dto.phoneEvidenceFileSize = phoneEvidenceFile.size;
                            dto.phoneEvidenceMimeType = phoneEvidenceFile.type;
                            fd.append('phoneEvidenceStream', phoneEvidenceFile);

                            dto.prospectPhone = this.formatPhone(this.get('inputs'));
                            dto.prospectEmail = this.get('model.prospectEmail');
                        }
                    }

                    fd.append('accountCreateDto', new Blob([JSON.stringify(dto)], { type: 'application/json' }));

                    this.get('session').authenticate('authenticator:jwt-custom', fd, '/account/create', false).then(function () {
                        if (_this.get('model.isStrongAuthentication')) {
                            _this.get('model').rollbackAttributes();
                            _this.send('displayAlertMessage', _this.get('intl').t('application.login.lastLoginDate') + _this.get('intl').t('application.login.firstLogin'), 'INFO');
                            _this.get('currentUser').updatefullName();
                            _this.get('currentUser').tunnelRedirection(deferred);
                        } else {
                            _this.get('session').set('data.authenticationchallenge-dto', {});
                            _this.get('session').set('data.authenticationanswers-dto', {});
                            _this.send('displayAlertMessage', _this.get('intl').t('finalization.weak-authentication-success'), 'SUCCESS');
                            _this.send('beforeTransitionTo');
                            _this.set('showSpinner', false);
                            _this.transitionToRoute('accountcreation.confirmfinalization');
                        }
                    }).catch(function (reason) {
                        if (reason.responseJSON) {
                            _this.set('showErrorMessageDiv', true);
                        }
                        _this.set('showSpinner', false);
                        _this.set('isRetry', true);
                        _this.get('currentUser').restoreSession();
                    });

                    deferred.promise.then(function (value) {
                        _this.send('beforeTransitionTo');
                        _this.set('showSpinner', false);
                        _this.transitionToRoute(value);
                    }).catch(function () {
                        _this.set('showSpinner', false);
                    });
                }
            },
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('home');
            },
            close: function close() {
                this.set('showErrorMessageDiv', false);
            }
        },

        formatPhone: function formatPhone(inputs) {
            // eslint-disable-next-line no-undef
            return inputs[0].getNumber(intlTelInputUtils.numberFormat.E164);
        }
    });
});