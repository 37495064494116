define('fpl-fgdr-depositorweb-webapp/controllers/contact', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'npm:b64-to-blob'], function (exports, _constants, _npmB64ToBlob) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        showSpinner: false,
        currentLanguage: Ember.computed('intl.locale', function () {
            var locale = this.get('intl.locale').toLocaleString();
            return locale.substr(0, locale.indexOf('-'));
        }),
        init: function init(arg) {
            this._super(arg);
            this.initFormOptions();
        },
        initFormOptions: function initFormOptions() {
            var _this = this;

            Ember.$.get(_constants.API_NAMESPACE + '/communications/forms/' + this.get('currentLanguage')).then(function (data) {
                if (Array.isArray(data)) {
                    _this.set('formOptions', data);
                    _this.set('model.selectedForm', data[0]);
                } else {
                    _this.set('formOptions', mapToKeyValue(data));
                    _this.set('model.selectedForm', getFirstValue(data));
                }
            });
        },

        actions: {
            edit: function edit() {
                this.set('disabled', false);
            },
            validate: function validate() {
                var _this2 = this;

                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var fd = new FormData();
                    fd.append('campaignRef', new Blob([this.get('session.data.campaign-dto').campaignRef], { type: 'text/plain' }));
                    if (this.get('currentUser').hasAccess("CONNECTED_ROLE")) {
                        fd.append('lastName', null);
                        fd.append('firstName', null);
                        fd.append('registrationNumber', null);
                        fd.append('denomination', null);
                    } else {
                        if (this.get('model.naturalPerson')) {
                            fd.append('lastName', new Blob([this.get('model.lastName')], { type: 'text/plain' }));
                            fd.append('firstName', new Blob([this.get('model.firstName')], { type: 'text/plain' }));
                        } else {
                            fd.append('registrationNumber', new Blob([this.get('model.registrationNumber')], { type: 'text/plain' }));
                            fd.append('denomination', new Blob([this.get('model.denomination')], { type: 'text/plain' }));
                        }
                    }
                    fd.append('model', new Blob([this.get('model.selectedForm')], { type: 'text/plain' }));
                    Ember.$.ajax({
                        url: _constants.API_NAMESPACE + "/communications/form",
                        data: fd,
                        processData: false,
                        contentType: false,
                        type: 'POST'
                    }).then(function (data) {
                        var blob = (0, _npmB64ToBlob.default)(data, 'application/octet-stream');
                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.target = '_blank';
                        var selectedForm = _this2.get('model.selectedForm');
                        if (selectedForm !== undefined) {
                            if (selectedForm.startsWith(_constants.COMMUNICATION_FORM.CORRESPONDENCE_FORM)) {
                                link.download = _this2.get('intl').t('contact.download-link.correspondence');
                            }
                            if (selectedForm.startsWith(_constants.COMMUNICATION_FORM.COMPLAINT_FORM)) {
                                link.download = _this2.get('intl').t('contact.download-link.complaint');
                            }
                        }
                        link.click();
                        _this2.set('showSpinner', false);
                        _this2.set('showErrorMessage', false);
                        _this2.set('showSuccessMessage', true);
                        _this2.set('disabled', true);
                    }).catch(function () {
                        _this2.set('showSpinner', false);
                    });
                }
            }
        }
    });


    function mapToKeyValue(data) {
        return Object.keys(data).map(function (key) {
            return { key: key, value: data[key] };
        });
    }

    function getFirstValue(data) {
        return Object.keys(data)[0];
    }
});