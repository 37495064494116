define('fpl-fgdr-depositorweb-webapp/components/inputs/input-postalcode', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _inputField, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Inpout for postal code.
     *
     * ```hbs
     * ie : {{inputs/input-postalcode id='identifier' type='text'value=model.attribute}}
     * ```
     */
    var InputComponent = _inputField.default.extend({
        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],
        type: 'text',
        class: 'form-control',

        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        isValid: Ember.computed('value', 'shouldDisplayValidations', function () {
            return this.get('shouldDisplayValidations') && _constants.REG_EX_ALPHANUMERIC.test(this.get('value'));
        }).readOnly(),
        shouldDisplayValidations: Ember.computed('didValidate', 'hasContent', 'shouldValidate', function () {
            if (typeof this.get('shouldValidate') === 'boolean') {
                return (this.get('didValidate') || this.get('hasContent')) && this.get('shouldValidate');
            } else {
                return this.get('didValidate') || this.get('hasContent');
            }
        }).readOnly(),
        showErrorMessage: Ember.computed('shouldDisplayValidations', 'isValid', 'hasContent', function () {
            return this.get('shouldDisplayValidations') && !this.get('isValid');
        }).readOnly(),
        actions: {
            changeValue: function changeValue(value) {
                this.set('value', value);
            }
        },

        init: function init() {
            this._super.apply(this, arguments);
            if (!this.get('inputId')) {
                this.set('inputId', 'input-' + this.get('inputSequence').next());
            }
        }

    });

    InputComponent.reopenClass({
        positionalParams: ['model', 'validatorModel', 'validatorAttributeName']
    });

    exports.default = InputComponent;
});