define('fpl-fgdr-depositorweb-webapp/components/inputs/radio-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set('realValue', this.get('value'));
            Ember.run.schedule('afterRender', this, function () {
                var radioButton = _this.$('input')[0];
                radioButton.checked = _this.get('checked') === _this.get('value');
                _this.$(radioButton).on('change', function () {
                    this.set('checked', this.get('realValue'));
                }.bind(_this));
            });
        }
    });
});