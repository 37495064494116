define('fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            var campaignTest = this.get('session.data.campaignTest') === undefined ? false : this.get('session.data.campaignTest');
            this.get('session').set('data.campaignTest', campaignTest);

            if (this.get('currentUser').hasAccess('CONNECTED_ROLE')) {
                this.set('routeIfAlreadyAuthenticated', 'home');
                return this._super.apply(this, arguments);
            }
            //All routes is unaccessible when campaignRef is null except /selectcampaign and /testcampaignaccess
            else if (!this.get('session.data.campaign-dto.campaignRef') && !transition.targetName.includes('testcampaignaccess') && !transition.targetName.includes('selectcampaign')) {
                    this.get('session').set('data.campaign-dto', {});
                    transition.abort();
                    this.transitionTo('selectcampaign');
                }
        }
    });
});