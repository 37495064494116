define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/noteligible', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});