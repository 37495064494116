define('fpl-fgdr-depositorweb-webapp/components/inputs/input-tel', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _inputField, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /*
     * Input for Phone.
     *
     * ```hbs
     * ie : {{inputs/input-tel value=model.attribute}}
     * ```
     */

    var InputComponent = _inputField.default.extend({
        intl: Ember.inject.service(),
        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],

        isValid: Ember.computed('value', 'validation', 'shouldDisplayValidations', 'hasContent', function () {
            if (!this.get('shouldDisplayValidations')) {
                return false;
            } else if (this.element.getElementsByTagName('input').length === 0) {
                return true;
            }

            if (this.get('shouldOnlyRelyOnValidators')) {
                return this.get('validation.isTruelyValid');
            } else {
                if (this.get('validation') && this.get('validation.isInvalid')) {
                    this.set('errorMessage', this.get('intl').t('errors.accountcreation.authentication.finalization.prospectPhone.not-empty-field'));
                    return false;
                }

                var telInput = this.element.getElementsByTagName('input')[0];
                if ((0, _commons.isTelValidFormat)(telInput)) {
                    return true;
                } else {
                    this.set('errorMessage', this.get('intl').t('errors.accountcreation.authentication.finalization.prospectPhone.invalid-format'));
                    return false;
                }
            }
        }).readOnly(),
        isInvalid: Ember.computed.not('isValid').readOnly(),

        didInsertElement: function didInsertElement() {
            var input = this.element.getElementsByTagName('input')[0];
            input.classList.add('form-control');
            // eslint-disable-next-line no-undef
            this.get('inputs').pushObject(intlTelInput(input, {
                initialCountry: 'fr',
                customPlaceholder: function customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
                    if (selectedCountryData.iso2 === 'fr') {
                        return '01 23 45 67 89';
                    }
                    return selectedCountryPlaceholder;
                }
            }));
        },


        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        shouldDisplayValidations: Ember.computed.or('didValidate', 'hasContent').readOnly(),
        showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'isInvalid').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);

            if (this.get('model')) {
                var name = this.get('name');
                Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + name));

                Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
            }
        }
    });

    InputComponent.reopenClass({
        positionalParams: ['model']
    });

    exports.default = InputComponent;
});