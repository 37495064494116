define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/finalization', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),
        intl: Ember.inject.service(),
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('FINALIZE_REGISTRATION_ROLE')) {
                this.transitionTo('home');
            }
        },
        model: function model() {
            return this.store.createRecord('finalization-dto');
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('inputs', Ember.A());
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('showSpinner', false);
            controller.set('showErrorMessage', false);
            controller.set('didValidate', false);
            controller.set('identificationStrength', this.get('session.data.authenticationchallenge-dto.identificationStrength'));
            controller.set('dfTelematicRef', this.get('session.data.authenticationchallenge-dto.dfTelematicRef'));
            controller.set('model.isStrongAuthentication', this.get('currentUser.user.identificationStrength') === _constants.STRENGTH.STRONG);
            controller.set('model.isVeryWeakAuthentication', this.get('currentUser.user.identificationStrength') === _constants.STRENGTH.VERY_WEAK);
            controller.set('cniTable', [this.get('intl').t('finalization.cni-toolTipContent.l1'), this.get('intl').t('finalization.cni-toolTipContent.l2'), this.get('intl').t('finalization.cni-toolTipContent.l3'), this.get('intl').t('finalization.cni-toolTipContent.l4')]);
            controller.set('phoneevidenceTable', [this.get('intl').t('finalization.phoneevidence-toolTipContent.l1'), this.get('intl').t('finalization.phoneevidence-toolTipContent.l2'), this.get('intl').t('finalization.phoneevidence-toolTipContent.l3')]);
        },


        actions: {
            // When you leave the page, you are disconnected, except if otp is valid
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});