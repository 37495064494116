define('fpl-fgdr-depositorweb-webapp/routes/accountcreation/authentication/communicationchannel', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        intl: Ember.inject.service(),
        beforeModel: function beforeModel() {
            if (!this.get('currentUser').hasAccess('PROVIDE_REGISTRATION_OTP_ROLE')) {
                this.transitionTo('home');
            } else {
                if (Ember.isEmpty(this.get('session.data.authenticationanswers-dto.creditCardAnswer')) && Ember.isEmpty(this.get('session.data.authenticationanswers-dto.savingAccountNumber')) && Ember.isEmpty(this.get('session.data.authenticationanswers-dto.checkingAccountNumber'))) {
                    this.send('displayAlertMessage', this.get('intl').t('errors.incomplete-answers-error'), 'ERROR');
                    this.transitionTo('contact');
                }

                if (Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.phones')) && Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.emails'))) {
                    this.transitionTo('accountcreation.finalization');
                }
            }
        },
        model: function model() {
            return this.store.createRecord('otpcode');
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('didValidate', false);
            controller.set('showErrorMessageOtp', false);
            controller.set('phones', this.get('session.data.authenticationchallenge-dto.phones'));
            controller.set('emails', this.get('session.data.authenticationchallenge-dto.emails'));
            // Init selected channel type
            if (!Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.phones'))) {
                controller.set('iKnowIt', _constants.CHANNEL_TYPE.PHONE_NUMBER);
            } else if (!Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.emails'))) {
                controller.set('iKnowIt', _constants.CHANNEL_TYPE.EMAIL);
            } else {
                // Note : in theory, it's not needed since we redirect to finalization if phones and emails are empty
                controller.set('iKnowIt', _constants.CHANNEL_TYPE.NONE);
            }
            // Note : payload media is defined in the controller as we don't know at this step what channel type will be selected
            controller.set('payload', this.get('store').createRecord('otprequest-dto', {
                fullName: this.get('session.data.authenticationchallenge-dto.fullName'),
                telematicRef: this.get('session.data.authenticationchallenge-dto.dfTelematicRef')
            }));
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('codeSent', false);
                controller.set('didValidate', false);
                controller.set('selectionPhoneNumber', null);
                controller.set('selectedEmail', null);
                controller.set('payload', controller.get('payload').rollbackAttributes());
            }
        }
    });
});