define('fpl-fgdr-depositorweb-webapp/app', ['exports', 'fpl-fgdr-depositorweb-webapp/resolver', 'ember-load-initializers', 'fpl-fgdr-depositorweb-webapp/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var App = Ember.Application.extend({
        modulePrefix: _environment.default.modulePrefix,
        podModulePrefix: _environment.default.podModulePrefix,
        Resolver: _resolver.default,

        ready: function ready() {
            var _this = this;

            Ember.$.ajaxSettings.traditional = true;
            Ember.$.ajaxPrefilter(function (options, originalOptions, request) {
                var session = _this.__container__.lookup('service:session'),
                    token = session.get('data.authenticated.token');
                if (token !== undefined) {
                    request.setRequestHeader('X-Auth-Token', token);
                }
            });
            Ember.$(document).ajaxSuccess(function () {
                var sessionTimeout = _this.__container__.lookup('service:session-timeout');
                sessionTimeout.initTimeout();
            });
        }
    });

    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

    exports.default = App;
});