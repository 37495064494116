define('fpl-fgdr-depositorweb-webapp/models/weakidentificationrequest-dto', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    /**
     * Model validations rules
     */
    var Validations = (0, _emberCpValidations.buildValidations)({
        naturalPerson: (0, _emberCpValidations.validator)('presence', true),
        // natural persons validators
        lastName: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.naturalPerson').volatile(),
            messageKey: 'errors.accountcreation.identification.identity.lastname'
        }),
        firstName: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.naturalPerson').volatile(),
            messageKey: 'errors.accountcreation.identification.identity.firstname'
        }),
        birthDate: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed.not('model.naturalPerson').volatile(),
            messageKey: 'errors.accountcreation.identification.identity.birthdate'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
            disabled: Ember.computed.not('model.naturalPerson').volatile(),
            messageKey: 'errors.accountcreation.identification.identity.birthdate'
        })],
        zipCode: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,registrationNumber}', function () {
                return !this.get('model.naturalPerson') && !this.get('model.zipCode') && this.get('model.registrationNumber');
            }).volatile(),
            messageKey: 'errors.accountcreation.identification.identity.zipcode'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^[0-9]+$/,
            disabled: Ember.computed('model.{naturalPerson,registrationNumber}', function () {
                return !this.get('model.naturalPerson') && !this.get('model.zipCode') && this.get('model.registrationNumber');
            }).volatile(),
            messageKey: 'errors.accountcreation.identification.identity.zipcode-letters'
        })],
        // legal person validators
        registrationNumber: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,denomination,zipCode}', function () {
                return this.get('model.naturalPerson') || !this.get('model.registrationNumber') && this.get('model.denomination') && this.get('model.zipCode');
            }).volatile(),
            messageKey: 'errors.accountcreation.identification.identity.registrationnumber'
        }),
        denomination: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            disabled: Ember.computed('model.{naturalPerson,registrationNumber}', function () {
                return this.get('model.naturalPerson') || !this.get('model.denomination') && this.get('model.registrationNumber');
            }).volatile(),
            messageKey: 'errors.accountcreation.identification.identity.denomination'
        })
    });

    /**
     * Represents data model for Weak Authentication
     */
    exports.default = _emberData.default.Model.extend(Validations, {
        campaignRef: attr('string'),
        naturalPerson: attr('boolean'),
        lastName: attr('string'),
        firstName: attr('string'),
        birthDate: attr('string'),
        zipCode: attr('string'),
        registrationNumber: attr('string'),
        denomination: attr('string')
    });
});