define('fpl-fgdr-depositorweb-webapp/components/form-buttons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        intl: Ember.inject.service(),
        tagName: '',
        cancelButtonLabel: null,
        validateButtonLabel: null,
        didValidate: false,
        showCancelButton: true,
        showValidateButton: true,
        showButtons: true,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('cancelButtonLabel', Ember.isEmpty(this.get('cancelButtonLabel')) ? this.get('intl').t('common-words.buttons.abandon') : this.get('cancelButtonLabel'));
            this.set('validateButtonLabel', Ember.isEmpty(this.get('validateButtonLabel')) ? this.get('intl').t('common-words.buttons.continue') : this.get('validateButtonLabel'));
        },

        actions: {
            validate: function validate() {
                this.set('didValidate', true);
                this.onValidate();
            },
            cancel: function cancel() {
                this.set('didValidate', false);
                this.onCancel();
            }
        }
    });
});