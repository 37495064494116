define('fpl-fgdr-depositorweb-webapp/components/inputs/e-input', ['exports', 'fpl-fgdr-depositorweb-webapp/components/inputs/input-field'], function (exports, _inputField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Email Input.
     *
     * ```hbs
     * ie : {{inputs/e-input initialEmail id='identifier' type='text'value=model.attribute}}
     * ```
     */
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var EmailInputComponent = _inputField.default.extend({
        tag: 'div',
        classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success'],
        type: 'email',
        tagName: 'div',
        class: 'form-control',

        hasContent: Ember.computed('value', function () {
            var isNotObjectAndNotEmpty = this.get('value') && !this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value'));
            var isNotEmptyObject = this.get('value') && this.get('value').hasOwnProperty('content') && !Ember.isEmpty(this.get('value.content'));
            return isNotObjectAndNotEmpty || isNotEmptyObject;
        }).readOnly(),
        isEmail: Ember.computed('value', function () {
            return emailRegex.test(this.get('value'));
        }).readOnly(),
        //isValid: and('hasContent', 'isEmail').readOnly(),
        isValid: Ember.computed('hasContent', 'isEmail', 'initialEmailValue', function () {
            if (this.get('initialEmailValue')) {
                return this.get('value') === this.get('initialEmailValue');
            } else {
                return this.get('hasContent') && this.get('isEmail');
            }
        }),
        shouldDisplayValidations: Ember.computed.or('didValidate', 'hasContent').readOnly(),
        notEmail: Ember.computed.not('isValid').readOnly(),
        showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'notEmail').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
        }
    });

    EmailInputComponent.reopenClass({
        positionalParams: ['initialEmailValue']
    });

    exports.default = EmailInputComponent;
});