define('fpl-fgdr-depositorweb-webapp/controllers/application', ['exports', 'lodash', 'fpl-fgdr-depositorweb-webapp/config/environment', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _lodash, _environment, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showErrorMessage: false,
        showSuccessMessage: false,
        alertMessage: ''
    });


    Ember.Controller.reopen({
        intl: Ember.inject.service(),
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        languages: _constants.LANGUAGES,
        onChangeLanguage: Ember.observer('selectedLanguage', function () {
            this.get('intl').setLocale(this.get('selectedLanguage'));
            this.get('session').set('data.selectedLanguage', this.get('selectedLanguage'));
            window.location.reload();
        }),
        selectedLanguage: Ember.computed('intl.locale', function () {
            return this.get('intl.locale').toLocaleString();
        }),
        isAuthenticated: Ember.computed('currentUser.user.role', function () {
            return this.get('currentUser').hasAccess("CONNECTED_ROLE");
        }),
        tabs: Ember.computed('isAuthenticated', 'session.data.newCommunicationCount', function () {
            return this.getTabs(this.get('isAuthenticated'));
        }),
        testMode: Ember.computed('session.data.campaignTest', function () {
            if (this.get('session.data.campaignTest') === undefined) {
                this.get('session').set('data.campaignTest', false);
            }
            return this.get('session.data.campaignTest');
        }),
        campaignRef: Ember.computed('session.data.campaign-dto.campaignRef', function () {
            return this.get('session.data.campaign-dto.campaignRef');
        }),
        checkIfCampaignExist: Ember.computed('campaignRef', function () {
            return this.get('campaignRef') !== undefined;
        }),
        stubMode: Ember.computed('testMode', function () {
            return _environment.default.environment === 'development' || _environment.default.environment === 'test';
        }),
        getTabs: function getTabs(isAuthenticated) {
            var tabs = [{
                link: 'home',
                title: this.get('intl').t('application.tabs.home-label')
            }];

            if (isAuthenticated) {
                tabs = _lodash.default.concat(tabs, [{
                    link: 'mycontactinformation',
                    title: this.get('intl').t('application.tabs.myContactInformation-label')
                }, {
                    link: 'payment',
                    title: this.get('intl').t('application.tabs.payment-label')
                }, {
                    link: 'myfile',
                    title: this.get('intl').t('application.tabs.myfile-label'),
                    notification: this.get('session.data.newCommunicationCount')
                }]);
            }

            tabs = _lodash.default.concat(tabs, [{
                link: 'contact',
                title: this.get('intl').t('application.tabs.contact-label')
            }, {
                link: 'faq',
                title: this.get('intl').t('application.tabs.faq-label')
            }]);
            return tabs;
        },


        actions: {
            openModal: function openModal(modalName) {
                return this.render(modalName, {
                    into: 'application',
                    outlet: 'modal'
                });
            },
            closeModal: function closeModal() {
                return this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },
            invalidateSession: function invalidateSession() {
                var _this = this;

                this.get('session').invalidate().then(function () {
                    _this.get('session').set('data.logout', true);
                    _this.transitionToRoute('login');
                });
            },
            disableTest: function disableTest() {
                this.get('session').set('data.campaignTest', false);
                this.get('session').set('data.campaign-dto', {});
                this.set('checkIfCampaignExist', false);
                this.transitionToRoute('selectcampaign');
            }
        }
    });
});