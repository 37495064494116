define('fpl-fgdr-depositorweb-webapp/components/guaranty-charts', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        intl: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set('showSpinner', true);

            Ember.$.ajax({
                context: this,
                url: _constants.API_NAMESPACE + '/amounts',
                type: 'GET',
                contentType: 'application/json'
            }).then(function (response) {

                var stateData = Ember.A();
                var fgdData = Ember.A();

                if (response.stateAmount === null && response.fgdAmount === null) {
                    _this.set('shouldDisplayDisclosure', true);
                    _this.set('showSpinner', false);
                } else {

                    // The order in the array is important for the pie-chart display
                    // Please keep it sorted according to the labels used for the legend
                    stateData.push(response.stateAmount.pendingAmount);
                    stateData.push(response.stateAmount.paidAmount);
                    stateData.push(response.stateAmount.clippedAmount);
                    stateData.push(response.stateAmount.notCoveredAmount);

                    fgdData.push(response.fgdAmount.pendingAmount);
                    fgdData.push(response.fgdAmount.paidAmount);
                    fgdData.push(response.fgdAmount.clippedAmount);
                    fgdData.push(response.fgdAmount.notCoveredAmount);

                    _this.set('stateData', stateData);
                    _this.set('fgdData', fgdData);

                    _this.set('stateLabels', _this.getLegend(response.stateAmount.operationalLimitAmount));
                    _this.set('fgdLabels', _this.getLegend(response.fgdAmount.operationalLimitAmount));
                    _this.set('fgdTitle', _this.get('intl').t('home.chart.fgd-title'));
                    _this.set('stateTitle', _this.get('intl').t('home.chart.state-title'));
                    _this.set('fgdTotalAmount', response.fgdAmount.totalAmount);
                    _this.set('stateTotalAmount', response.stateAmount.totalAmount);
                    _this.set('fgdTotalAmountLabel', _this.getTotalAmountLabel(response.fgdAmount.totalAmount));
                    _this.set('stateTotalAmountLabel', _this.getTotalAmountLabel(response.stateAmount.totalAmount));

                    _this.set('showSpinner', false);
                }
            });
        },
        getLegend: function getLegend(operationalLimit) {
            return [this.get('intl').t('home.chart.amount-pending'), this.get('intl').t('home.chart.amount-paid'), this.get('intl').t('home.chart.amount-clipped', { 'operationalLimit': this.get('intl').formatNumber(operationalLimit, _constants.OPTIONS_INTL_AMOUNT) }), this.get('intl').t('home.chart.amount-not-covered')];
        },
        getTotalAmountLabel: function getTotalAmountLabel(totalAMount) {
            return [this.get('intl').t('home.chart.total-amount-label-1'), this.get('intl').t('home.chart.total-amount-label-2'), this.get('intl').formatNumber(totalAMount, _constants.OPTIONS_INTL_AMOUNT)];
        },


        shouldDisplayFgdChart: Ember.computed('fgdTotalAmount', function () {
            return this.get('fgdTotalAmount') !== 0 && this.get('fgdTotalAmount') !== null;
        }),
        shouldDisplayStateChart: Ember.computed('stateTotalAmount', function () {
            return this.get('stateTotalAmount') !== 0 && this.get('stateTotalAmount') !== null;
        })

    });
});