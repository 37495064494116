define('fpl-fgdr-depositorweb-webapp/components/has-access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     Simple component that will avoid the rendering of a specific block if the current user does not have the correct role.
    
     Example:
     ```hbs
     {{#has-access 'CONNECTED_ROLE'}}
     ...
     {{/has-access}}
     ```
     */
    var HasAccessComponent = Ember.Component.extend({
        tagName: '',
        currentUser: Ember.inject.service(),

        hasAccess: Ember.computed('role', function () {
            return this.get('currentUser').hasAccess(this.get('role'));
        })
    });

    HasAccessComponent.reopenClass({
        positionalParams: ['role']
    });

    exports.default = HasAccessComponent;
});