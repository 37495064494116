define('fpl-fgdr-depositorweb-webapp/router', ['exports', 'fpl-fgdr-depositorweb-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL,
        newCommunicationCount: Ember.on('didTransition', function () {
            this.send('getNewCommunicationsCount');
        })
    });

    Router.map(function () {
        this.route('home');
        this.route('sitemap');
        this.route('contact');
        this.route('faq');
        this.route('generalconditions');
        this.route('legalnotice');
        this.route('myfile');
        this.route('payment');
        this.route('forgotpassword');
        this.route('forgotpasswordmails');
        this.route('forgotpasswordotp');
        this.route('resetpassword');
        this.route('selectcampaign');
        this.route('accountlocked');

        this.route('accountcreation', function () {
            this.route('noteligible');
            this.route('conditionsvalidation');
            this.route('identification', function () {
                this.route('reference');
                this.route('identity');
            });

            this.route('authentication', function () {
                this.route('bankinfo');
                this.route('communicationchannel');
            });
            this.route('finalization');
            this.route('confirmfinalization');
        });
        this.route('mycontactinformation');
        this.route('updatepassword');
        this.route('testcampaignaccess');
        this.route('sendmessage');
        this.route('login');
        this.route('loginotp');
        this.route('404', { path: '/*path' });
    });

    exports.default = Router;
});