define('fpl-fgdr-depositorweb-webapp/components/navigation-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentRoute: '',
        intl: Ember.inject.service(),
        pageNumber: Ember.computed('currentRoute', function () {
            switch (this.get('currentRoute')) {
                case 'selectcampaign':
                    return this.get('intl').t('application.pagination.selectcampaign');
                case 'login':
                    return this.get('intl').t('application.pagination.login');
                case 'loginotp':
                    return this.get('intl').t('application.pagination.loginotp');
                case 'contact':
                    return this.get('intl').t('application.pagination.contact');
                case 'faq':
                    return this.get('intl').t('application.pagination.faq');
                case 'sitemap':
                    return this.get('intl').t('application.pagination.sitemap');
                case 'legalnotice':
                    return this.get('intl').t('application.pagination.legalnotice');
                case 'generalconditions':
                    return this.get('intl').t('application.pagination.generalconditions');
                case 'forgotpassword':
                    return this.get('intl').t('application.pagination.forgotpassword');
                case 'forgotpasswordmails':
                    return this.get('intl').t('application.pagination.forgotpasswordmails');
                case 'forgotpasswordotp':
                    return this.get('intl').t('application.pagination.forgotpasswordotp');
                case 'resetpassword':
                    return this.get('intl').t('application.pagination.resetpassword');
                case 'accountlocked':
                    return this.get('intl').t('application.pagination.accountlocked');
                case 'accountcreation.conditionsvalidation':
                    return this.get('intl').t('application.pagination.conditionsvalidation');
                case 'accountcreation.identification.reference':
                    return this.get('intl').t('application.pagination.reference');
                case 'accountcreation.identification.identity':
                    return this.get('intl').t('application.pagination.identity');
                case 'accountcreation.noteligible':
                    return this.get('intl').t('application.pagination.noteligible');
                case 'accountcreation.authentication.bankinfo':
                    return this.get('intl').t('application.pagination.bankinfo');
                case 'accountcreation.authentication.communicationchannel':
                    return this.get('intl').t('application.pagination.communicationchannel');
                case 'accountcreation.finalization':
                    return this.get('intl').t('application.pagination.finalization');
                case 'home':
                    return this.get('intl').t('application.pagination.home');
                case 'mycontactinformation':
                    return this.get('intl').t('application.pagination.mycontactinformation');
                case 'payment':
                    return this.get('intl').t('application.pagination.payment');
                case 'myfile':
                    return this.get('intl').t('application.pagination.myfile');
                case 'sendmessage':
                    return this.get('intl').t('application.pagination.sendmessage');
                case 'updatepassword':
                    return this.get('intl').t('application.pagination.updatepassword');
            }
        })
    });
});