define('fpl-fgdr-depositorweb-webapp/authenticators/jwt-custom', ['exports', 'ember-simple-auth-token/authenticators/jwt', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _jwt, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jwt.default.extend({

        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        authenticate: function authenticate(credentials, endpoint) {
            var _this = this;

            var contentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'application/json';


            return new Promise(function (resolve, reject) {
                Ember.$.ajax({
                    context: _this,
                    url: _constants.API_NAMESPACE + endpoint,
                    type: 'POST',
                    data: credentials,
                    contentType: contentType,
                    processData: false
                }).then(function (response) {
                    try {
                        if (!Ember.isEmpty(response.token)) {
                            _this.handleAuthResponse(response);

                            // Init the currentUser service with the informations provided in the jwt token
                            var data = _this.getTokenData(response.token);
                            _this.get('currentUser')._initCurrentUser(Ember.Object.create(data['usr-data']));
                        }
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                }, function (error) {

                    // Ember-simple-auth will clear the auth infos on reject
                    // However we need to keep those informations during account creation
                    // We have to backup the data and manually set it back in the callback
                    // Use the 'app/services/current-user.js' service to restore the session
                    _this.get('session').set('data.authenticatedBkp', _this.get('session.data.authenticated'));

                    reject(error);
                });
            });
        },
        invalidate: function invalidate() {
            var _this2 = this;

            // Back call to blacklist the current token
            return new Promise(function (resolve) {
                Ember.$.ajax({
                    url: _constants.API_NAMESPACE + '/auth/disconnect',
                    type: 'POST',
                    data: JSON.stringify({ token: _this2.get('session.data.authenticated.token') }),
                    contentType: 'application/json'
                }).always(function () {
                    _this2._super();
                    _this2.get('session').set('data.authenticatedBkp', {});
                    resolve();
                });
            });
        }
    });
});