define('fpl-fgdr-depositorweb-webapp/controllers/accountcreation/authentication/communicationchannel', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants', 'fpl-fgdr-depositorweb-webapp/services/commons'], function (exports, _constants, _commons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        showErrorMessageOtp: false,
        subOtpToken: _constants.OTP_STUBMODE,
        showSpinner: false,
        subOtpTokenWeak: _constants.OTP_STUBMODE_WEAK,
        session: Ember.inject.service(),
        disablePhone: Ember.computed('session.data.authenticationchallenge-dto.phones', function () {
            return Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.phones'));
        }),
        disableEmail: Ember.computed('session.data.authenticationchallenge-dto.emails', function () {
            return Ember.isEmpty(this.get('session.data.authenticationchallenge-dto.emails'));
        }),
        iKnowPhone: Ember.computed('iKnowIt', function () {
            return this.get('iKnowIt') === _constants.CHANNEL_TYPE.PHONE_NUMBER;
        }),
        iKnowEmail: Ember.computed('iKnowIt', function () {
            return this.get('iKnowIt') === _constants.CHANNEL_TYPE.EMAIL;
        }),
        disablePhoneField: Ember.computed('iKnowPhone', function () {
            return !this.get('iKnowPhone');
        }),
        disableEmailField: Ember.computed('iKnowEmail', function () {
            return !this.get('iKnowEmail');
        }),
        actions: {
            validation: function validation() {
                var _this = this;

                var dto = {};
                var challengedto = this.get('session.data.authenticationanswers-dto');
                var challengeAnswer = {};
                challengeAnswer.creditCardAnswer = challengedto.creditCardAnswer;
                challengeAnswer.accountNumber = challengedto.checkingAccountNumber ? challengedto.checkingAccountNumber : challengedto.savingAccountNumber;
                var url = "";
                if (this.get('iKnowPhone')) {
                    dto.channelHash = this.get('selectionPhoneNumber');
                    dto.challengeAnswer = challengeAnswer;
                    this.initPayload(_constants.OTP_CHANNEL.SMS);
                    url = '/otp';
                } else if (this.get('iKnowEmail')) {
                    dto.channelHash = this.get('selectedEmail');
                    dto.challengeAnswer = challengeAnswer;
                    this.initPayload(_constants.OTP_CHANNEL.EMAIL);
                    url = '/otp';
                } else {
                    dto = challengeAnswer;
                    url = '/identification/veryweak';
                }
                this.set('showSpinner', true);

                this.get('session').authenticate('authenticator:jwt-custom', JSON.stringify(dto), url).then(function () {
                    _this.set('didValidate', false);
                    if (_this.get('iKnowIt') === _constants.CHANNEL_TYPE.NONE) {
                        _this.transitionToRoute('accountcreation.finalization');
                    } else {
                        _this.set('codeSent', true);
                    }
                }).catch(function (reason) {
                    _this.send('displayAlertMessage', _this.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                        return _this.get('intl').exists(value);
                    }, 'errors.exception.BadChallengeException')), 'ERROR');
                    _this.transitionToRoute('contact');
                }).finally(function () {
                    _this.set('showSpinner', false);
                });
            },
            confirmationToken: function confirmationToken() {
                var _this2 = this;

                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    this.get('session').authenticate('authenticator:jwt-custom', this.get('model.value'), '/otp/validation').then(function () {

                        var authenticationanswers = _this2.get('session.data.authenticationanswers-dto');
                        var successLabel = '';
                        if (_this2.get('iKnowPhone')) {
                            authenticationanswers.confirmedPhone = _this2.get('selectionPhoneNumber');
                            authenticationanswers.phoneValueSecured = _this2.get('selectionPhoneNumber');
                            successLabel = 'commchannel.phone-validation-success';
                        } else if (_this2.get('iKnowEmail')) {
                            authenticationanswers.confirmedEmail = _this2.get('selectedEmail');
                            successLabel = 'commchannel.mail-validation-success';
                        }
                        _this2.get('session').set('data.authenticationanswers-dto', authenticationanswers);
                        _this2.send('displayAlertMessage', _this2.get('intl').t(successLabel), 'SUCCESS');
                        _this2.set('showSpinner', false);
                        _this2.send('resetErrorMessage');
                        _this2.transitionToRoute('accountcreation.finalization');
                    }).catch(function (reason) {
                        _this2.set('showSpinner', false);
                        if (reason.responseJSON.errors.message === 'key:errors.exception.BadChallengeEnrolmentIdentifiedException') {
                            if (reason.responseJSON.errors.attempts === 0) {
                                _this2.send('resetErrorMessage');
                                _this2.transitionToRoute('accountlocked');
                            }
                            var errorMessage = [_this2.get('intl').t((0, _commons.getErrorKey)(reason.responseJSON.errors.message, function (value) {
                                return _this2.get('intl').exists(value);
                            }, 'errors.generic'), { 'attempts': reason.responseJSON.errors.attempts }), _this2.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l1'), _this2.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l2'), _this2.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l3'), _this2.get('intl').t('errors.exception.BadChallengeEnrolmentIdentifiedException-instructions.l4')];
                            _this2.send('displayAlertMessage', errorMessage, 'ERROR');
                            _this2.get('currentUser').restoreSession();
                        } else {
                            _this2.send('displayAlertMessage', _this2.get('intl').t('errors.accountcreation.authentication.channel.otp-max-attempt'), 'ERROR');
                            _this2.transitionToRoute('contact');
                        }
                    });
                }
            },
            back: function back() {
                this.set('codeSent', false);
                this.set('didValidate', false);
                this.set('selectionPhoneNumber', null);
                this.set('selectedEmail', null);
            },
            cancel: function cancel() {
                this.get('currentUser').logoutWithoutLosingCampaign();
                this.transitionToRoute('login');
            }
        },
        init: function init() {
            this._super.apply(this, arguments);
            this.set('codeSent', false);
        },
        initPayload: function initPayload(otpChannel) {
            this.set('payload', this.get('store').createRecord('otprequest-dto', {
                media: otpChannel,
                fullName: this.get('session.data.authenticationchallenge-dto.fullName'),
                telematicRef: this.get('session.data.authenticationchallenge-dto.dfTelematicRef')
            }));
        }
    });
});