define('fpl-fgdr-depositorweb-webapp/helpers/breadcrumb-value', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        intl: Ember.inject.service(),
        compute: function compute(params) {
            switch (params.toString()) {
                case 'mycontactinformation':
                    return this.get('intl').t('application.breadcrumb.myContactInformation');
                case 'payment':
                    return this.get('intl').t('application.breadcrumb.payment');
                case 'myfile':
                    return this.get('intl').t('application.breadcrumb.folder');
                case 'sendmessage':
                    return this.get('intl').t('application.breadcrumb.sendMessage');
                case 'contact':
                    return this.get('intl').t('application.breadcrumb.contact');
                case 'faq':
                    return this.get('intl').t('application.breadcrumb.faq');
                case 'forgotpassword':
                    return this.get('intl').t('application.breadcrumb.forgotpassword');
                case 'updatepassword':
                    return this.get('intl').t('application.breadcrumb.updatepassword');
                case 'sitemap':
                    return this.get('intl').t('application.breadcrumb.sitemap');
                case 'legalnotice':
                    return this.get('intl').t('application.breadcrumb.legalNotice');
                case 'generalconditions':
                case 'conditionsvalidation':
                    return this.get('intl').t('application.breadcrumb.accountCreationConditionsValidation');
                case 'accountcreation':
                    return this.get('intl').t('application.breadcrumb.accountCreation');
                case 'identification':
                    return this.get('intl').t('application.breadcrumb.accountCreationIdentification');
                case 'authentication':
                    return this.get('intl').t('application.breadcrumb.accountCreationAuthentication');
                default:
                    return '';
            }
        }
    });
});