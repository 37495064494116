define('fpl-fgdr-depositorweb-webapp/components/breadcrumb-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        pageLink: 'home',
        isHome: Ember.computed('pageLink', function () {
            return this.get('pageLink') === 'home' || this.get('pageLink') === 'login' || this.get('pageLink') === 'index';
        }),
        displayNavigation: Ember.computed('pageLink', function () {
            return this.get('pageLink') !== 'selectcampaign';
        }),
        routes: Ember.computed('pageLink', function () {
            return this.get('pageLink').split('.');
        })
    });
});