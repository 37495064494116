define('fpl-fgdr-depositorweb-webapp/routes/myfile', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/authenticated-route-mixin', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _authenticatedRouteMixin, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        startIndex: 0,
        endIndex: 10,
        size: 10,

        model: function model(params) {
            var _this = this;

            return new Promise(function (resolve) {
                Ember.$.get(_constants.API_NAMESPACE + '/communications/' + _this.get('startIndex') + '/' + _this.get('endIndex')).then(function (response) {
                    resolve({
                        coms: response.communicationDtos,
                        maxResults: response.maxResults,
                        fullName: _this.get('session').get('data.fullName'),
                        ref: _this.get('currentUser.user.telematicRef'),
                        startIndex: _this.get('startIndex'),
                        endIndex: _this.get('endIndex'),
                        size: _this.get('size'),
                        filter: params.filter
                    });
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            Ember.$.get(_constants.API_NAMESPACE + '/df/getStatus').then(function (response) {
                controller.set('status', response);
            });
        },


        // prevent query parameter persistence when transitioning to other page
        resetController: function resetController(controller) {
            controller.set('filter', false);
        },


        actions: {
            // To the page change, disable messages
            willTransition: function willTransition() {
                this.send('resetMessage');
            }
        }
    });
});