define('fpl-fgdr-depositorweb-webapp/routes/forgotpassword', ['exports', 'fpl-fgdr-depositorweb-webapp/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        model: function model() {
            return this.store.createRecord('strongidentificationrequest-dto', {
                hasReference: true
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('showErrorMessage', false);
            controller.set('showSpinner', false);
            controller.set('didValidate', false);
        }
    });
});