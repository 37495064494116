define('fpl-fgdr-depositorweb-webapp/controllers/myfile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['filter'],
        filter: false,

        actions: {
            decreaseNewCommunicationsCount: function decreaseNewCommunicationsCount() {
                this.get('session').set('data.newCommunicationCount', this.get('session').get('data.newCommunicationCount') - 1);
            }
        }
    });
});