define('fpl-fgdr-depositorweb-webapp/controllers/sendmessage', ['exports', 'fpl-fgdr-depositorweb-webapp/services/constants'], function (exports, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        showSpinner: false,
        isTextAreaValid: Ember.computed('model.message', function () {
            return !Ember.isEmpty(this.get('model.message'));
        }),
        actions: {
            validate: function validate() {
                if (this.get('model.validations.isValid')) {
                    this.set('showSpinner', true);
                    var fd = new FormData();

                    var uploadedFile = Ember.$('form input[type=file]')[0].files[0];
                    if (uploadedFile !== undefined) {
                        var document = {
                            fileName: uploadedFile.name,
                            fileSize: uploadedFile.size,
                            mimeType: uploadedFile.type
                        };
                        fd.append('documentInfo', new Blob([JSON.stringify(document)], { type: 'application/json' }));
                        fd.append('data', uploadedFile);
                    }

                    var message = this.get('model.message');
                    if (message !== undefined) {
                        fd.append('message', message);
                    }

                    Ember.$.ajax({
                        context: this,
                        url: _constants.API_NAMESPACE + '/communications/create',
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        data: fd,
                        success: function () {
                            var _this = this;

                            Ember.run(function () {
                                _this.transitionToRoute('/myfile').then(function () {
                                    _this.set('showSpinner', false);
                                    _this.send('displayAlertMessage', _this.get('intl').t('sendmessage.send-sucess'), 'SUCCESS');
                                });
                            });
                        }.bind(this),
                        error: function error() {
                            this.send('displayAlertMessage', this.get('intl').t('errors.generic'), 'ERROR');
                            this.set('showSpinner', false);
                        }
                    });
                }
            }
        }
    });
});